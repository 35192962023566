import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import {
  getCompany,
  getContractCategory,
  getDepartments,
  getIssues,
  getLocations,
  getPriorities,
  getProjects,
  getQueues,
  getRoles,
  getSkills,
  getSlas,
  getSubIssues,
  getTaskCategory,
  getTaskType,
  getTicketCategory,
  getTicketType,
  getlineOfBusiness,
} from "../../../../_metronic/requests/FilterRequest";
import { getGeneralSetting, getLocationDetails } from "../../../../_metronic/requests/GeneralSettingRequest";
import {
  getLayoutandFiltersList,
  getLayoutandfilters,
  getMonthdata,
  getPhase,
  getPlanningData,
  getResourceAvailability,
  getResources,
  getResourcesWorkType,
  getToPlan,
  getToPlanServiceCallStatus,
  getToPlanTaskStatus,
  selectLayoutAndMakeActive,
} from "../../../../_metronic/requests/PlanningRequest";
import { getResourceSetting } from "../../../../_metronic/requests/ResourceSettingRequest";
import { Loading } from "../../../components/loading/Loading";
import ViewSettings from "../../../components/modals/ViewSettings/viewSettings";
import { AUTOTASK_ENTITY } from "../../../constants/global";
import { webSocketContext } from "../../../context/WebSocketContext";
import {
  setisCreated,
  setisFilter,
  setispageLoad,
  setplanningCache,
  storeCapacityCompanyIds,
  storeCapacityDepartmentIds,
  storeCapacityPeopleIds,
  storeCapacityProjectIds,
  storeCapacityRoleIds,
  storeCapacitycontractcategoryids,
  storeCapacityprioritiesids,
  storeCapacityprojectLeadsids,
  storeCapacityqueuesids,
  storeCapacityskillids,
  storeCapacityslasids,
  storeCapacitytaskCategoryids,
  storeCapacityticketCategoryids,
  storeCapacityticketTypeids,
  storeClearAllFilter
} from "../../../counterSlice";
import debounce from "../../../helpers/debounce";
import { calculatePlanningData } from "../../../helpers/planningScreenCalculation";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { decryptData } from "../../auth/components/encryptionUtils";
import { useAuth } from "../../auth/core/AuthCognito";
import { getUserByToken } from "../../auth/core/_requests";
import { getTooltipData } from "../../superadmin/hint-tour/core/_request";
import PlanningView from "./PlannigView";
import PlanningCapacityGraph from "./PlanningCapacity";
import TodoPlan from "./TodoPlan";

import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../dashboard-management/core/_request";
import { loadFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "../../../helpers/latest_selection";

const PlanningPage = () => {
  const { currentUser } = useAuth();
  const initial_sync_completed_or_not =
    currentUser?.data?.user?.initial_sync_completed;
  const [axisCategoryList, setaxisCategoryList] = useState([]);
  const [capacityTaskList, setcapacityTaskList] = useState([]);
  const [freeTaskList, setfreeTaskList] = useState([]);
  const [plannedTaskList, setplannedTaskList] = useState([]);
  const [overBookedTaskList, setoverBookedTaskList] = useState([]);
  const [capacity_type, setcapacityType] = useState<any>("week");
  const [capacityLabelVal, setCapacityLableVal] = useState("");
  const [confirmLabelVal, setConfirmLableVal] = useState("");
  const [availableCapacityLabelVal, setavailableCapacityLabelVal] =
    useState("");
  const [overbookLabelVal, setoverbookLabelVal] = useState("");
  const [confirmIconPath, setconfirmIconPath] = useState("");
  const [capacityIconPath, setcapacityIconPath] = useState("");
  const [availableCapacityIconPath, setavailableCapacityIconPath] =
    useState("");
  const [overbookIconPath, setoverbookIconPath] = useState("");
  const [offlineCount, setofflineCount] = useState(0);
  const dispatch = useAppDispatch();
  const [isFilterEnable, setFilterEnable] = useState(true);

  const [monthquery, setmonthquery] = useState(false);
  const [planningList, setplanningList] = useState(false);
  const [resourceList, setResourceList] = useState(false);
  const [resourceFilter, setResourceFilter] = useState(false);
  const [payload, setPayload] = useState(() => {
    const savedPreferences = loadFromLocalStorage('planning_layout');
    return savedPreferences?.planning_filter || {
      resources: [],
      projects: [],
      departments: [],
      companies: [],
      locations: [],
      skills: [],
      lineOfBusiness: [],
      roles: [],
      workgroups: [],
      start_date: "",
      end_date: "",
      view_type: "",
      type_ids: [],
      servicecall_status_ids: [],
      queues_ids: [],
      search_conditions: [],
    }; // Default preferences
  });
  const [todopayload, setTodoPayload] = useState(()=>{
    const savedPreferences = loadFromLocalStorage('planning_layout');
    return savedPreferences?.task_list_filter || {
      resources: [],
      projects: [],
      departments: [],
      companies: [],
      workgroups: [],
      start_date: null,
      end_date: null,
      roles: [],
      project_leads: [],
      queues: [],
      sla: [],
      contract_category: [],
      skills: [],
      lineOfBusiness: [],
      page: 1,
      items_per_page: 5,
      statuses: [],
      servicecall_statuses: [],
      types: [],
      priority: [],
      task_categories: [],
      ticket_categories: [],
      ticket_types: [],
      show_more_less: false,
    }
  });
  let [planningTask, setPlanningTask] = useState<any>(null);
  const [selectedId, setSelectedId] = useState("");
  const [isShown, setIsShown] = useState("d-none");
  const [istodoShown, settodoIsShown] = useState(false);
  const [isviewShown, setViewIsShown] = useState(false);
  let [btnColor, setBtnColor] = useState("#164387");
  let [activetextcolor, setBtnText] = useState("text-white");
  let [spanclassName, setspanclassName] = useState("");
  let [todoclassName, settodoclassName] = useState("");
  let [viewclassName, setViewclassName] = useState("");
  let [heightClass, setheightClassName] = useState("heightShow");
  let [toplanActive, setToplanActive] = useState("");
  let [toplanactivetextcolor, setToPlanBtnText] = useState("");
  let [viewBtnbackground, setviewBtnbackground] = useState("");

  let [Viewbtncolor, setViewbtncolor] = useState("");

  const [sortTooltip, setsortTooltip] = useState("Sort resource by");
  const [sortIcon, setsortIcon] = useState(
    "/media/icons/duotune/new-icons/sort-down.svg"
  );
  let [sortActive, setSortActive] = useState("");
  let [sortactivetextcolor, setSortBtnText] = useState("");
  let [isCapacitydailytype, setisCapacitydailytype] = useState("day");
  let [selectedtodoStatus, setselectedtodoStatus] = useState([]);
  let [selectedTaskStatus, setselectedTaskStatus] = useState([]);
  let [selectedTask, setselectedTask] = useState([]);
  let [sorttodoWith, setsorttodoWith] = useState("");
  let [selectedsortDuedate, setselectedsortDuedate] = useState("");
  let [selectedsortCompany, setselectedsortCompany] = useState("");
  let [selectedsortMenu, setselectedsortMenu] = useState("");
  let [syncdata, setsyncdata] = useState(false);
  let [dateHeaderCal, setdateHeaderCal] = useState([]);
  const [hasShowMore, setHasShowMore] = useState<any>(false);
  const [isLoading, setIsLoading] = useState(false);
  const ids = useAppSelector((state) => state.counter);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [capacityGraptData, setCapacityGraptData] = useState(null);
  const { socketMessage }: any = useContext(webSocketContext);
  const viewSettingsData = useSelector(
    (state: any) => state?.viewSettings?.PlannigViewData
  );
  const [currentDate, setCurrentDate] = useState<any>(new Date());

  const websocketCallback = useCallback(
    (event: any) => {
      let eData = JSON.parse(event.data);
      const ENV = process.env.ENV
      if (eData.organizationId === currentUser?.data?.user?.organizationId) {
        if (
          eData.type === "todo_changes_success" ||
          eData.type === "todo_changes_failed"
        ) {
          toplanrefetch();
          refetchMonthdata();
          // refreshtodo(eData?.data[0], "success", eData);
        } else if (
          eData.type === 'unified_sync'
        ) {
          if(ENV !== "prod"){
            console.log(`${eData?.syncType} ${eData?.status ? 'Process Completed Successfully' : 'Process Failed'}`)
          }
          if (eData.syncType === 'fullSync') {
            window.location.reload();
          } else {
            dispatch(setispageLoad(false));
            dispatch(setisFilter(true));
            reloadPage();
          }
          // refreshtodo(eData?.data, "error", eData);
        } else if (
          eData.type == "update_sc_success" ||
          eData.type == "update_sc_changes_failed" ||
          eData.type == "delete_sc_success" ||
          eData.type == "delete_sc_changes_failed"
        ) {
          dispatch(setispageLoad(false));
          toplanrefetch();
          refetchMonthdata();
        }
      }
    },
    [socketMessage]
  );

  useEffect(() => {
    if (socketMessage) {
      websocketCallback(socketMessage);
    }
  }, [socketMessage]);

  let [todosorticon, settodosorticon] = useState(
    "/media/icons/duotune/extra/sort-up.svg"
  );
  useEffect(() => {
    if (capacity_type == "year" && isCapacitydailytype == "day") {
      setisCapacitydailytype("week");
    } else {
      setisCapacitydailytype(isCapacitydailytype);
    }
  }, [capacity_type, isCapacitydailytype]);

  const showtodocomponent = () => {
    setViewclassName("");
    toggleDropdown("");
    todoclassName === "todoShow"
      ? settodoclassName("")
      : settodoclassName("todoShow");
    if (toplanActive === "" || toplanactivetextcolor === "") {
      setToplanActive("#164387");
      setviewBtnbackground("");
      setToPlanBtnText("text-white");
      setViewbtncolor("");
    } else {
      setToplanActive("");
      setToPlanBtnText("");
    }
    saveToLocalStorage('planning_layout', {
      planning_filter: Object.assign(payload, {
        showtodo: !istodoShown,
        showcapacity: isShown,
        showView: isviewShown,
      }),
      task_list_filter: todopayload,
    })
    settodoIsShown((current) => !current);
  };

  const showviewcomponent = () => {
    toggleDropdown("");
    if (viewBtnbackground === "" || Viewbtncolor === "") {
      setviewBtnbackground("#164387");
      setToplanActive("");
      setViewbtncolor("text-white");
      setToPlanBtnText("");
    } else {
      setviewBtnbackground("");
      setViewbtncolor("");
    }
    settodoclassName("");
    viewclassName === "viewShow"
      ? setViewclassName("")
      : setViewclassName("viewShow");
    setViewIsShown((current) => !current);
  };

  const {
    isFetching: isfetchlayoutandfilters,
    data: layoutandfilters,
  } = useQuery(
    `planning-layout-filters`,
    async () => {
      return await getLayoutandfilters({layoutpage:"planning"});
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    isFetching: isfetchplanbucketlayoutandfilters,
    data: planbucketlayoutandfilters,
  } = useQuery(
    `planbucket-layout-filters`,
    async () => {
      return await getLayoutandfilters({layoutpage:"planbucket"});
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const {
    refetch: refetchLayouts,
    data: layoutAndFiltersData,
  } = useQuery(
    "layoutAndFilters", // Unique key for this query
    async () => {
      return await getLayoutandFiltersList("planning");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    refetch: refetchplanbucketLayouts,
    data: planbucketlayoutAndFiltersData,
  } = useQuery(
    "planbucketlayoutAndFilters", // Unique key for this query
    async () => {
      return await getLayoutandFiltersList("planbucket");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isfetchgeneralsetting,
    data: generalSetting,
  } = useQuery(
    `planning-resource-setting`,
    async () => {
      return await getGeneralSetting("");
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        localStorage.setItem("starttime", data?.start_time);
        localStorage.setItem("endtime", data?.end_time);
        data?.extended_morning_start &&
          localStorage.setItem(
            "extendedMorningStart",
            data?.extended_morning_start
          );
        data?.extended_morning_end &&
          localStorage.setItem(
            "extendedMorningEnd",
            data?.extended_morning_end
          );
        data?.extended_evening_start &&
          localStorage.setItem(
            "extendedEveningStart",
            data?.extended_evening_start
          );
        data?.extended_evening_end &&
          localStorage.setItem(
            "extendedEveningEnd",
            data?.extended_evening_end
          );
        localStorage.setItem("time_format", data?.time_format);
        localStorage.setItem("timezone", data?.timezone);
        localStorage.setItem("timeline_view", data?.planning_screen_view);
      },
    }
  );

  const {
    isFetching: isfetchresourcesetting,
    refetch: refetchresourcesetting,
    data: resource_settings,
  } = useQuery(
    "resources-",
    async () => {
      return await getResourceSetting("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const selectActiveLayout = async (layoutId: any, layoutType = "planning") => {
    localStorage.setItem("planningSelectedLayout", layoutId);
    let response = await selectLayoutAndMakeActive(layoutId, layoutType);
    if (response.status === 200) {
      localStorage.setItem("viewSettingIsShow", "0");
      removeFromLocalStorage("planning_layout")
      window.location.reload();
    }
  };

  const weekLabelCache = {};

  function getWeekLabel(date) {
    const weekKey = date.clone().startOf("week").format("YYYY-WW"); // Unique key for each week

    if (weekLabelCache[weekKey]) {
      return weekLabelCache[weekKey];
    }

    // Get the start and end of the week
    let startOfWeek = date.clone().startOf("week"); // Sunday as the start of the week
    let endOfWeek = date.clone().startOf("week").add(6, "days"); // Saturday as the end of the week

    // Format the dates
    let formattedStart = startOfWeek.format("D MMM");
    let formattedEnd = endOfWeek.format("D MMM");

    // Create the label
    let weekLabel = `${formattedStart} - ${formattedEnd}`;

    // Store in cache
    weekLabelCache[weekKey] = weekLabel;

    return weekLabel;
  }

  const {
    isFetching: isFetchingAvailability,
    refetch: refetchAvailability,
    data: responseAvailability,
  } = useQuery(
    "resources_availability-",
    async () => {
      return await getResourceAvailability(
        false,
        payload,
        initial_sync_completed_or_not
      );
    },
    {
      enabled: resourceList,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: async (data: any) => {
        const capacityHours = calculateCapacityHours(moment(payload.start_date), moment(payload.end_date), data.capacityArr)
        data.capacityHours = capacityHours;
        setResourceList(false);
        setResourceFilter(false);
        setsyncdata(false);
        dispatch(setplanningCache(true));
        localStorage.setItem("planningCache", "true");
      },
    }
  );

  const calculateCapacityHours = (startDateObj: any, endDateObj: any, capacityArr: any) => {
    const capacityHours = {};

    for (let date = startDateObj; date.isSameOrBefore(endDateObj); date.add(1, "days")) {
      const dateStr = date.format("YYYY-MM-DD");
      if (!capacityHours[dateStr]) {
        capacityHours[dateStr] = 0;
      }
      const key = isEven(date) ? "even" : "odd";
      const day = date.format("ddd").toLowerCase();
      capacityHours[dateStr] += +capacityArr?.[key]?.[day] || 0;
    }

    return capacityHours;
  };

  const isEven = (date: any) => {
    const weekOfYear = date.week();
    return weekOfYear % 2 === 0 ? true : false;
  }

  const {
    isFetching: isFetchingPlanning,
    refetch: refetchPlanning,
    data: responsePlanning,
  } = useQuery(
    "resources_planning-",
    async () => {
      return await getPlanningData(
        false,
        payload,
        initial_sync_completed_or_not
      );
    },
    {
      enabled: planningList,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: async () => {
        setplanningList(false);
        setsyncdata(false);
        dispatch(setplanningCache(true));
        localStorage.setItem("planningCache", "true");
      },
    }
  );

  const [isLoadingMonthYear, SetIsLoadingMonthYear] = useState(false);
  const [isLoadingView, SetIsLoadingView] = useState(false);
  //refetch the method when responseMonthdata is failed during the caches geneartion time.
  const handleMonthYearError = () => {
    SetIsLoadingMonthYear(true);
    setTimeout(() => {
      refetchMonthdata();
      SetIsLoadingMonthYear(true);
    }, 5000);
  };
  const [monthYearDataResponse, setMonthYearDataResponse] = useState(false)
  let {
    isFetching: isFetchingMonthdata,
    refetch: refetchMonthdata,
    data: responseMonthdata,
  } = useQuery(
    "resources-monthdata_timeline" + monthquery,
    async () => {
      const resourceIds: any[] =
        responseAvailability?.allResource?.map((i: any) => i.id) || [];
      const viewType = ["month", "year"];
      try {
        if (viewType.indexOf(payload.view_type) !== -1) {
          SetIsLoadingView(true);
          const response: any = await getMonthdata({
            ...payload,
            resourceIds,
            initial_sync_completed_or_not,
          });
          let message = (response?.message || "")
            .split(" ")
            .join("_")
            .toLowerCase();
          const code = response?.response?.status;
          if (
            viewType.indexOf(payload.view_type) !== -1 &&
            ((code >= 429 && code <= 505) || message === "network_error")
          ) {
            handleMonthYearError();
          } else {
            let modifiedData = await calculatePlanningData(
              response?.data?.data || [],
              generalSetting,
              resource_settings?.data || [],
              payload.view_type
            );
            SetIsLoadingMonthYear(false);
            return modifiedData;
          }
        }
      } catch (e) {
        const code = e?.response?.status;
        let message = (e?.message || "").split(" ").join("_").toLowerCase();
        if (
          viewType.indexOf(payload.view_type) !== -1 &&
          ((code >= 429 && code <= 505) || message === "network_error")
        ) {
          handleMonthYearError();
        }
      }
    },
    {
      enabled: monthquery,
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        SetIsLoadingView(false);
        setmonthquery(false);
      },
    }
  );

  useEffect(() => {
    if (
      !isFetchingPlanning &&
      !isFetchingMonthdata &&
      !isFetchingAvailability
    ) {
      dispatch(setispageLoad(false));
    }
  }, [
    isFetchingMonthdata,
    isFetchingAvailability,
    isFetchingPlanning
  ]);

  const {
    isFetching: istoplanfetch,
    refetch: toplanrefetch,
    data: toplanlist,
  } = useQuery(
    ["todo-plan", todopayload],
    () => getToPlan(todopayload),
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setofflineCount(0);
        dispatch(setisCreated(false));
      },
    }
  );

  const {
    isFetching: isfetchstatus,
    refetch: refetchstatus,
    data: todo_status,
  } = useQuery(
    "todo-status",
    async () => {
      return await getToPlanTaskStatus();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isfetchservicecallstatus,
    data: todo_status_serviceCall,
  } = useQuery(
    "todo-status-serviceCall",
    async () => {
      return await getToPlanServiceCallStatus();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  function areEqual(array1: any[], array2: string | any[]) {
    if (array1?.length === array2?.length) {
      return array1.every((element) => {
        if (array2.includes(element)) {
          return true;
        }
        return false;
      });
    }
    return false;
  }

  const {
    isFetching: isDepartmentFetch,
    refetch: refetchFilterDepartment,
    data: departments,
  } = useQuery(
    "department-filter",
    () => {
      // debugger
      return getDepartments();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isPeopleFetch,
    refetch: refetchFilterPeople,
    data: peoples,
  } = useQuery(
    "people",
    () => {
      // debugger
      return getResources("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isProjectFetch, data: projects } = useQuery(
    "project",
    () => {
      return getProjects();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isCompanyFetch, data: companies } = useQuery(
    "company",
    () => {
      // debugger
      return getCompany();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isLocationFetch,
    refetch: refetchFilterLocation,
    data: locations,
  } = useQuery(
    "locations",
    () => {
      // debugger
      return getLocations();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { isFetching: isSkillFetch, data: skills } = useQuery(
    "skills",
    () => {
      // debugger
      return getSkills();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: islineOfBusinessFetch,
    refetch: refetchFilterlineOfBusiness,
    data: lineOfBusiness,
  } = useQuery(
    "lineOfBusiness",
    () => {
      // debugger
      return getlineOfBusiness();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isRoleFetch,
    refetch: refetchFilterRole,
    data: roles,
  } = useQuery(
    "roles-filter-list",
    () => {
      // debugger
      return getRoles();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isQueuesFetch,
    refetch: refetchFilterQueues,
    data: queues,
  } = useQuery(
    "Queues",
    () => {
      // debugger
      return getQueues();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isPrioritiesFetch,
    refetch: refetchFilterPriorities,
    data: priorities,
  } = useQuery(
    "Priorities",
    () => {
      // debugger
      return getPriorities();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isSlasFetch,
    refetch: refetchFilterSlas,
    data: slas,
  } = useQuery(
    "Slas",
    () => {
      // debugger
      return getSlas();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isIssuesFetch,
    refetch: refetchIssues,
    data: issues,
  } = useQuery(
    "Issues",
    () => {
      // debugger
      return getIssues();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isSubIssuesFetch,
    refetch: refetchSubIssues,
    data: subIssues,
  } = useQuery(
    "sub-issues",
    () => {
      // debugger
      return getSubIssues();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isTicketTypeFetch,
    refetch: refetchFilterTicketType,
    data: ticketType,
  } = useQuery(
    "TicketType",
    () => {
      // debugger
      return getTicketType();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isTicketCategoryFetch,
    refetch: refetchFilterTicketCategory,
    data: ticketCategory,
  } = useQuery(
    "TicketCategory",
    () => {
      // debugger
      return getTicketCategory();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isTaskCategoryFetch,
    refetch: refetchFilterTaskCategory,
    data: taskCategory,
  } = useQuery(
    "taskCategory",
    () => {
      // debugger
      return getTaskCategory();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isTaskTypeFetch,
    refetch: refetchFilterTaskType,
    data: taskType,
  } = useQuery(
    "taskType",
    () => {
      // debugger
      return getTaskType();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isContractCategoryFetch,
    refetch: refetchFilterContractCategory,
    data: contractCategory,
  } = useQuery(
    "contractCategory",
    () => {
      // debugger
      return getContractCategory();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isfetchingworktype,
    refetch: refetchworktype,
    data: worktype,
  } = useQuery(
    "resources-worktype",
    () => {
      return getResourcesWorkType("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const {
    isFetching: isfetchingphase,
    refetch: refetchphase,
    data: phase,
  } = useQuery(
    "resources-phase",
    () => {
      return getPhase("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: location } = useQuery(
    "location-details",
    async () => {
      return await getLocationDetails();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const getUserApi = async (token: any) => {
    const { data: user } = await getUserByToken(token);
    localStorage.setItem("starttime", user?.data?.setting?.start_time);
    localStorage.setItem("endtime", user?.data?.setting?.end_time);
    localStorage.setItem("time_format", user?.data?.setting?.time_format);
    localStorage.setItem("timezone", user?.data?.setting?.timezone);
  };

  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const myParam: any = params.get("mydata");
  const key: any = params.get("key");

  if (myParam != null || myParam != undefined) {
    const encodedParam = decodeURIComponent(myParam);
    const encodedKey = decodeURIComponent(key);
    const decryptedAuth = decryptData(encodedParam, encodedKey);
    getUserApi(decryptedAuth.result.token);
    navigate("/apps/planning/resources");
  }

  const reloadPage = () => {
    if(!isfetchlayoutandfilters){
      toplanrefetch();
      refetchPlanning();
      refetchAvailability();
      if (payload?.view_type === "day" || payload?.view_type === "week") {
        setplanningList(true);
      }else{
        setmonthquery(true);
      }
    }
  };

  const refreshtodo = (edata: any, type: any, responseData: any) => {
    if (edata && edata?._id) {
      edata.id = edata?._id;
    }
    let data: any = JSON.parse(JSON.stringify(sorttododata));
    let sortData: any;
    if (responseData?.dataTodelete) {
      data = data.filter((item: any) => item.id != responseData?.dataTodelete);
    }
    if (edata) {
      sortData = sorttododata.filter((item: any) => item.id == edata?.id);
      if (type == "success") {
        if (sortData?.length > 0 && todopayload?.types.includes(edata?.type)) {
          data = data.filter((item: any) => !(item.id == edata?.id));
          data.unshift(edata);
        } else {
          setofflineCount(offlineCount + 1);
          data.unshift(edata);
        }
      } else {
        data = data.filter((item: any) => !(item.id == edata?.id));
        setofflineCount(offlineCount - 1);
      }
    }
    setSorttododata(data);
  };

  const refreshAvailability = (withRefresh?: boolean) => {
    if (withRefresh) {
      dispatch(setplanningCache(false));
    }
    setplanningList(true);
  };

  const sortData = (e: any) => {
    payload.sort_field != "" ? setSortActive("#164387") : setSortActive("");
    payload.sort_field != ""
      ? setSortBtnText("text-white")
      : setSortBtnText("");
    // payload.sort_field = e.target.getAttribute('data-name');
    if (e.target.getAttribute("data-name") == "people_asc") {
      setsortTooltip("Ascending with resource name");
      setsortIcon("/media/icons/duotune/extra/sort-up.svg");
    } else if (e.target.getAttribute("data-name") == "people_desc") {
      setsortTooltip("Descending with resource name");
      setsortIcon("/media/icons/duotune/new-icons/sort-down.svg");
    } else {
      setsortTooltip("Sort data");
      setsortIcon("/media/icons/duotune/new-icons/sort-down.svg");
    }
    setPayload({ ...payload, sort_field: e.target.getAttribute("data-name") });
    //setQuery((Math.random() + 1).toString(36).substring(7))
  };

  const changeToggleBtnTxt = () => {
    // const moreLessBtn = document.querySelector(".showMoreLessToggleBtn span.lbl") as HTMLElement;
    // if (moreLessBtn) {
    //   moreLessBtn.innerHTML = 'more'
    //   moreLessBtn.classList.remove("less")
    //   moreLessBtn.classList.add("more")
    // }
  };

  const updatePage = (page: number | null) => {
    setTodoPayload({ ...todopayload, page: page });
    changeToggleBtnTxt();
  };

  const changeitemperpage = (e: any) => {
    let latestPayload = Object.assign({}, todopayload, {
      items_per_page: parseInt(e.target.value),
      page: 1,
    });
    setTodoPayload(latestPayload);
    changeToggleBtnTxt();
  };

  useEffect(() => {
    if (isFetchingMonthdata) {
      setMonthYearDataResponse(true);
    } else {
      setMonthYearDataResponse(false);
      planningCapacityFilter(payload.view_type, isCapacitydailytype, "call");
    }
  }, [isFetchingMonthdata, responseMonthdata?.data]);

  useEffect(() => {
    if (!isFetchingPlanning && !isFetchingAvailability) {
      capacityHoursData();
    }
  }, [isFetchingPlanning, isFetchingAvailability]);

  function generateCapacityGraphData(
    responseMonthdata,
    type,
    capacity_graph_type
  ) {
    // Extract data for axis and chart categories
    let axisDataWeek = [];
    let axisWeekLyAvailability = [];
    let axisMonthLyAvailability = [];
    let formataxisWeek = [];
    let capcityHoursWeek = [];
    let freeHoursWeek = [];
    let plannedHoursWeek = [];
    let overbooked = [];
    let weeknumbers = [];

    if (type === "day" || type === "week") {
      const plannedHoursDataObj = responseMonthdata?.plannedHours || {};
      const capacityHourssDataObj = responseMonthdata?.capacityHours || {};
      const capacityHoursArr = Object.keys(capacityHourssDataObj) || [];
      for (let i = 0; i < capacityHoursArr.length; i++) {
        let dateStr = capacityHoursArr[i];
        let date = moment(dateStr);
        let planned = plannedHoursDataObj?.[dateStr] || 0;
        let capacity = capacityHourssDataObj?.[dateStr] || 0;
        let free = capacity - planned;
        let over = 0;
        if (free < 0) {
          over = free;
          free = 0;
        }
        let weekLabel = getWeekLabel(date);
        axisDataWeek.push(date);
        axisWeekLyAvailability.push(weekLabel);
        axisMonthLyAvailability.push(date.format("MMM").toUpperCase());
        formataxisWeek.push(date.format("DD MMM"));
        capcityHoursWeek.push(
          Number((Math.round(capacity * 100) / 100).toFixed(2))
        );
        freeHoursWeek.push(Number((Math.round(free * 100) / 100).toFixed(2)));
        plannedHoursWeek.push(
          Number((Math.round(planned * 100) / 100).toFixed(2))
        );
        overbooked.push(
          Number((Math.round(Math.abs(over) * 100) / 100).toFixed(2))
        );
        weeknumbers.push(date.week());
      }
      if (type === "week") {
        if (["week", "month"].indexOf(capacity_graph_type) !== -1) {
          axisMonthLyAvailability = [axisMonthLyAvailability[0]];
          axisWeekLyAvailability = [axisWeekLyAvailability[0]];
          plannedHoursWeek = [
            plannedHoursWeek.reduce((acc, val) => acc + val, 0),
          ];
          freeHoursWeek = [freeHoursWeek.reduce((acc, val) => acc + val, 0)];
          overbooked = [overbooked.reduce((acc, val) => acc + val, 0)];
        }
      }
    } else {
      let dateArr: any = JSON.parse(
        JSON.stringify(responseMonthdata?.dayData || [])
      );
      let data2: any = JSON.parse(
        JSON.stringify(responseMonthdata?.weekData || [])
      );
      let sortedWeekdate: any[] = [];
      if (
        ["fweek", "week", "month", "year"].includes(type) &&
        capacity_graph_type === "week"
      ) {
        sortedWeekdate = JSON.parse(
          JSON.stringify(responseMonthdata?.weekData || [])
        );
      } else if (
        ["fweek", "week", "month", "year"].includes(type) &&
        capacity_graph_type === "month"
      ) {
        sortedWeekdate = JSON.parse(
          JSON.stringify(responseMonthdata?.monthData || [])
        );
      }

      if (sortedWeekdate?.length == 0) {
        sortedWeekdate = dateArr?.sort((a: any, b: any) =>
          a.start?.localeCompare(b.start)
        );
      }
      if (type === "year") {
        setdateHeaderCal(data2);
      } else {
        setdateHeaderCal(dateArr);
      }
      sortedWeekdate?.forEach((item) => {
        axisDataWeek.push(item.start);
        axisWeekLyAvailability.push(item.weekdatelabel);
        axisMonthLyAvailability.push(item.monthdatelabel);
        formataxisWeek.push(moment.utc(item.start).format("DD MMM"));
        capcityHoursWeek.push(
          Number((Math.round(item.capacity * 100) / 100).toFixed(2))
        );
        freeHoursWeek.push(
          Number((Math.round(item.free * 100) / 100).toFixed(2))
        );
        plannedHoursWeek.push(
          Number((Math.round(item.planned * 100) / 100).toFixed(2))
        );
        overbooked.push(
          Number((Math.round(Math.abs(item.over) * 100) / 100).toFixed(2))
        );
        weeknumbers.push(item.weeknumber);
      });
    }

    // Set axis category list based on type and capacity graph type
    let axisCategoryList = [];
    if (
      (["fweek", "week"].includes(type) && capacity_graph_type === "week") ||
      ((type === "week" || type === "fweek") && capacity_graph_type === "month")
    ) {
      axisCategoryList = axisWeekLyAvailability;
    } else if (
      (type === "month" || type === "year") &&
      capacity_graph_type === "month"
    ) {
      axisCategoryList = axisMonthLyAvailability;
    } else if (
      (type === "month" || type === "year") &&
      capacity_graph_type === "week"
    ) {
      axisCategoryList = weeknumbers;
    } else {
      axisCategoryList = formataxisWeek;
    }

    // Aggregate data if type is week and capacity graph type is month
    if (type === "week" && capacity_graph_type === "month") {
      const sumArray = (arr) => arr.reduce((acc, val) => acc + val, 0);
      plannedHoursWeek = [sumArray(plannedHoursWeek)];
      freeHoursWeek = [sumArray(freeHoursWeek)];
      overbooked = [sumArray(overbooked)];
    }

    // Add padding to the arrays
    const padding = 0;
    capcityHoursWeek.unshift(padding);
    capcityHoursWeek.push(padding);
    plannedHoursWeek.unshift(padding);
    plannedHoursWeek.push(padding);
    formataxisWeek.unshift(" ");
    formataxisWeek.push(" ");
    axisWeekLyAvailability.unshift(" ");
    axisWeekLyAvailability.push(" ");
    freeHoursWeek.unshift(padding);
    freeHoursWeek.push(padding);
    overbooked.unshift(padding);
    overbooked.push(padding);
    weeknumbers.unshift(" ");
    weeknumbers.push(" ");
    axisMonthLyAvailability.unshift(" ");
    axisMonthLyAvailability.push(" ");
    // Update state variables
    setcapacityTaskList(capcityHoursWeek);
    setfreeTaskList(freeHoursWeek.map((hour) => hour.toFixed(2)));
    setoverBookedTaskList(overbooked.map((hour) => hour.toFixed(2)));
    setplannedTaskList(plannedHoursWeek.map((hour) => hour.toFixed(2)));
    setaxisCategoryList(axisCategoryList);
  }

  const capacityHoursData = () => {
    const viewType = ["day", "week"];
    if (viewType.indexOf(payload.view_type) !== -1) {
      const capacityHoursDatesArr: string[] = Object.keys(
        responseAvailability?.capacityHours || {}
      );

      const updatedResponsePlanning = {
        ...responsePlanning,
        capacityHours: responseAvailability?.capacityHours,
      };

      generateCapacityGraphData(
        updatedResponsePlanning,
        payload.view_type,
        isCapacitydailytype
      );

      const capacityBarData = [];
      capacityHoursDatesArr.forEach((capacityHoursDate) => {
        capacityBarData.push({
          planned: responsePlanning?.plannedHours?.[capacityHoursDate] || 0,
          capacity: responseAvailability?.capacityHours[capacityHoursDate],
          start: capacityHoursDate,
        });
      });
      setdateHeaderCal(capacityBarData);
    }
      setCapacityGraptData({
      plannedHours: responsePlanning?.plannedHours,
      capacityHours: responseAvailability?.capacityHours,
    });
  }

  // , capacity_filter_type1 = capacity_filter_type
  const planningCapacityFilter = async (
    type: any,
    capacity_graph_type = isCapacitydailytype,
    call = ""
  ) => {
    if (type === "year" && capacity_graph_type === "day") {
      capacity_graph_type = "week";
    }
    setisCapacitydailytype(capacity_graph_type);
    setcapacityType(type);

    setCapacityLableVal("Total capacity");
    setConfirmLableVal("Planned capacity");
    setavailableCapacityLabelVal("Left capacity");
    setoverbookLabelVal("Total overbooked");
    setconfirmIconPath("/media/icons/duotune/extra/confirm-workload-new.svg");
    setcapacityIconPath("/media/icons/duotune/extra/capacity-new-latest.svg");
    setavailableCapacityIconPath(
      "/media/icons/duotune/extra/available-capacity-new.svg"
    );
    setoverbookIconPath("/media/icons/duotune/extra/overbooked-capacity.svg");
    //setCapacityGraptData
    if (isShown === "d-flex" || call === "call") {
      if ((responseMonthdata?.data) && (type === 'month' || type === 'year')) {
        generateCapacityGraphData(responseMonthdata, type, capacity_graph_type);
      } else if (capacityGraptData?.plannedHours) {
        generateCapacityGraphData(capacityGraptData, type, capacity_graph_type);
      }
    }
  };

  const handleDailyFilter = async () => {
    planningCapacityFilter(capacity_type, "day");
  };

  const handleWeeklyFilter = async () => {
    planningCapacityFilter(capacity_type, "week");
  };

  const handleMonthlyFilter = async () => {
    planningCapacityFilter(capacity_type, "month");
  };

  const handleHeaderView = (event: any, inst: any) => {
    dispatch(setispageLoad(true));
    let payloadlatest = JSON.parse(JSON.stringify(payload));
    const sub_enddate = moment(event.lastDay)
      .subtract(1, "days")
      .format("ddd DD MMM YYYY HH:mm:ss");
    payloadlatest.end_date = moment(sub_enddate).format("YYYY-MM-DD");
    payloadlatest.start_date = moment(event.firstDay).format("YYYY-MM-DD");
    const diff = Math.round(
      Math.abs(
        (event.lastDay.setHours(0) - event.firstDay.setHours(0)) /
        (24 * 60 * 60 * 1000)
      )
    );
    // console.log(diff)
    if (diff > 1 && diff <= 8) {
      payloadlatest.view_type = "week";
    } else if (diff > 8 && diff <= 32) {
      payloadlatest.view_type = "month";
    } else if (diff > 32) {
      payloadlatest.view_type = "year";
    } else {
      payloadlatest.view_type = "day";
    }
    if(payloadlatest.view_type === 'year'){
      let year = moment(payloadlatest.start_date).year();
      let weeknumber = moment(payloadlatest.start_date).week();
      let weekday = moment().year(year).week(weeknumber).day("Thursday");
      setCurrentDate(weekday);
    }else{
      setCurrentDate(payloadlatest.start_date);
    }
    setcapacityType(payloadlatest.view_type);
    if (responseAvailability) {
      const capacityHours = calculateCapacityHours(moment(payloadlatest.start_date), moment(payloadlatest.end_date), responseAvailability?.capacityArr)
      responseAvailability.capacityHours = capacityHours;
    }
    setPayload(payloadlatest);
  };
  /* End planning capacity */
  let inputHandler = debounce((e) => {
    let latestPayload = Object.assign({}, todopayload, {
      search: e.target.value,
      page: 1,
    });
    setTodoPayload(latestPayload);
    toggleDropdown("");
  }, 300);

  const showcomponent = () => {
    spanclassName === "capacityShow"
      ? setspanclassName("")
      : setspanclassName("capacityShow");
    heightClass === "heightShow"
      ? setheightClassName("defaultHeight")
      : setheightClassName("heightShow");
    btnColor === "" ? setBtnColor("#164387") : setBtnColor("");
    activetextcolor === "" ? setBtnText("text-white") : setBtnText("");
    isShown === "d-none" ? setIsShown("d-flex") : setIsShown("d-none");
    saveToLocalStorage('planning_layout', {
      planning_filter: Object.assign(payload, {
        showtodo: istodoShown,
        showcapacity: isShown === "d-none" ? "d-flex" : "d-none",
      }),
      task_list_filter: todopayload,
    })
  };

  /* Filter */
  useEffect(() => {
    let changefilter = false;
    let latestpayload = JSON.parse(JSON.stringify(payload));
    if (
      payload?.resources &&
      !areEqual(payload.resources, ids.people_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.resources = ids.people_ids;
      changefilter = true;
      setResourceFilter(true);
    }
    if (
      payload?.companies &&
      !areEqual(payload.companies, ids.company_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.companies = ids.company_ids;
      changefilter = true;
    }
    if (
      payload?.departments &&
      !areEqual(payload.departments, ids.department_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.departments = ids.department_ids;
      changefilter = true;
      setResourceFilter(true);
    }
    if (
      payload?.projects &&
      !areEqual(payload.projects, ids.project_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.projects = ids.project_ids;
      changefilter = true;
    }
    if (
      payload?.roles &&
      !areEqual(payload.roles, ids.role_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.roles = ids.role_ids;
      changefilter = true;
      setResourceFilter(true);
    }
    if (
      payload?.type_ids &&
      !areEqual(payload.type_ids, ids.type_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.type_ids = ids.type_ids;
      changefilter = true;
    }
    if (
      payload?.servicecall_status_ids &&
      !areEqual(
        payload.servicecall_status_ids,
        ids.servicecall_status_ids || []
      ) &&
      ids.callApiforfilter
    ) {
      latestpayload.servicecall_status_ids = ids.servicecall_status_ids;
      changefilter = true;
    }
    if (
      payload?.queues_ids &&
      !areEqual(payload.queues_ids, ids.queues_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.queues_ids = ids.queues_ids;
      changefilter = true;
    }
    if (
      payload?.skills &&
      !areEqual(payload.skills, ids.skill_ids) &&
      ids.callApiforfilter
    ) {
      latestpayload.skills = ids.skill_ids;
      changefilter = true;
    }
    if (
      payload?.lineOfBusiness &&
      !areEqual(payload?.lineOfBusiness || [], ids?.lineOfBusiness_ids || []) &&
      ids.callApiforfilter
    ) {
      latestpayload.lineOfBusiness = ids.lineOfBusiness_ids;
      changefilter = true;
    }
    if (ids.callApiforfilter) {
      if (ids?.appointment_filter?.groups?.length > 0) {
        changefilter = true;
        if (
          latestpayload?.type_ids &&
          latestpayload?.type_ids?.includes("appointments")
        ) {
          latestpayload.search_conditions = ids?.appointment_filter?.groups;
        } else {
          latestpayload.search_conditions = [];
        }
      } else {
        latestpayload.search_conditions = [];
      }
    }
    if (changefilter && isFilterEnable) {
      dispatch(setispageLoad(true));
      setPayload(latestpayload);
    }
  }, [
    ids.people_ids,
    ids.company_ids,
    ids.department_ids,
    ids.project_ids,
    ids.role_ids,
    ids.type_ids,
    ids.servicecall_status_ids,
    ids.queues_ids,
    ids.skill_ids,
    ids.lineOfBusiness_ids,
    ids?.appointment_filter?.groups,
  ]);

  const Capcity_ids = useAppSelector((state) => state.counter);
  useEffect(() => {
    let ischangefilter = false;
    let todofilterpayload: any = {};
    if (
      !areEqual(todopayload.projects, Capcity_ids.project_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.projects = Capcity_ids.project_capacity_ids;
      ischangefilter = true;
    }
    if (
      !areEqual(todopayload.resources, Capcity_ids.people_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.resources = Capcity_ids.people_capacity_ids;
      ischangefilter = true;
    }
    if (
      !areEqual(todopayload.companies, Capcity_ids.company_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.companies = Capcity_ids.company_capacity_ids;
      ischangefilter = true;
    }
    if (
      !areEqual(todopayload.departments, Capcity_ids.department_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.departments = Capcity_ids.department_capacity_ids;
      ischangefilter = true;
    }
    if (
      !areEqual(todopayload.roles, Capcity_ids.role_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.roles = Capcity_ids.role_capacity_ids;
      ischangefilter = true;
    }
    if (
      !areEqual(
        todopayload.project_leads,
        Capcity_ids.project_leads_capacity_ids
      ) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.project_leads = Capcity_ids.project_leads_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(todopayload.queues, Capcity_ids.queues_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.queues = Capcity_ids.queues_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(todopayload.priority, Capcity_ids.priorities_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.priority = Capcity_ids.priorities_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(
        todopayload.task_categories,
        Capcity_ids.taskCategory_capacity_ids
      ) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.task_categories = Capcity_ids.taskCategory_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(
        todopayload.ticket_categories,
        Capcity_ids.ticketCategory_capacity_ids
      ) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.ticket_categories =
        Capcity_ids.ticketCategory_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(
        todopayload.ticket_types,
        Capcity_ids.ticketType_capacity_ids
      ) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.ticket_types = Capcity_ids.ticketType_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(
        todopayload.contract_category,
        Capcity_ids.contract_category_ids
      ) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.contract_category = Capcity_ids.contract_category_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(todopayload.skills, Capcity_ids.skill_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.skills = Capcity_ids.skill_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(
        todopayload?.lineOfBusiness || [],
        Capcity_ids?.lineOfBusiness_capacity_ids || []
      ) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.lineOfBusiness =
        Capcity_ids.lineOfBusiness_capacity_ids;
      ischangefilter = true;
    }

    if (
      !areEqual(todopayload.sla, Capcity_ids.slas_capacity_ids) &&
      Capcity_ids.callApifortodofilter
    ) {
      todofilterpayload.sla = Capcity_ids.slas_capacity_ids;
      ischangefilter = true;
    }
    if (ischangefilter) {
      todofilterpayload.page = 1;
      let latestPayload = Object.assign({}, todopayload, todofilterpayload);
      setTodoPayload(latestPayload);
    }
  }, [
    Capcity_ids.project_capacity_ids,
    Capcity_ids.people_capacity_ids,
    Capcity_ids.company_capacity_ids,
    Capcity_ids.department_capacity_ids,
    Capcity_ids.role_capacity_ids,
    Capcity_ids.project_leads_capacity_ids,
    Capcity_ids.queues_capacity_ids,
    Capcity_ids.priorities_capacity_ids,
    Capcity_ids.taskCategory_capacity_ids,
    Capcity_ids.ticketCategory_capacity_ids,
    Capcity_ids.ticketType_capacity_ids,
    Capcity_ids.skill_capacity_ids,
    Capcity_ids.slas_capacity_ids,
    Capcity_ids.contract_category_ids,
    Capcity_ids.lineOfBusiness_capacity_ids,
  ]);

  let [sorttododata, setSorttododata] = useState<any>([]);
  const [SelectedStatusid, setSelectedStatusid] = useState<any>(Array);
  const [SelectedServicecallStatusid, setSelectedServicecallStatusid] =
    useState<any>(Array);

    const handleTodoStatusChange = (e: any) => {
      let statusespayload: any = {};
      if (e.target.value !== "All") {
        if (e.target.checked) {
          statusespayload.statuses = [
            ...selectedTaskStatus,
            parseInt(e.target.value),
          ];
        } else {
          statusespayload.statuses = selectedTaskStatus.filter(
            (item: any) => item !== parseInt(e.target.value)
          );
        }
        setselectedTaskStatus(statusespayload.statuses);
      } else {
        statusespayload.statuses = [];
        setselectedTaskStatus(statusespayload.statuses);
        let latestPayload = Object.assign({}, todopayload, statusespayload);
        setTodoPayload(latestPayload);
        toggleDropdown("", "no");
      }
    };
  /* End Filter */

  // Servicecall filter

  const handleServiceCallStatusChange = (e: any) => {
    let statusespayload: any = {};
    if (e.target.value !== "All") {
      if (e.target.checked) {
        statusespayload.servicecall_statuses = [
          ...selectedtodoStatus,
          parseInt(e.target.value),
        ];
      } else {
        statusespayload.servicecall_statuses = selectedtodoStatus.filter(
          (item: any) => item !== parseInt(e.target.value)
        );
      }
      setselectedtodoStatus(statusespayload.servicecall_statuses);
    } else {
      statusespayload.servicecall_statuses = [];
      setselectedtodoStatus(statusespayload.servicecall_statuses);
      let latestPayload = Object.assign({}, todopayload, statusespayload);
      setTodoPayload(latestPayload);
      toggleDropdown("pservicecall_status", "no");
    }
  };
  // end:servicecall filter
  const handleAllSelectCheckbox = (e: any) => {
    let statusespayload: any = {};
    if (e.target.checked) {
      statusespayload.statuses = todo_status
        ?.filter((s: any) => s.isActive === true)
        ?.map((x: any) => parseInt(x.id));
    } else {
      statusespayload.statuses = [];
    }
    setselectedTaskStatus(statusespayload.statuses);
  };

  const handleAllServiceCallChecked = (e: any) => {
    let statusespayload: any = {};
    if (e.target.checked) {
      statusespayload.servicecall_statuses = todo_status_serviceCall
        ?.filter((s: any) => s.isActive === true)
        ?.map((x: any) => parseInt(x.id));
    } else {
      statusespayload.servicecall_statuses = [];
    }
    setselectedtodoStatus(statusespayload.servicecall_statuses);
  };

  const handleAllTypeSelectCheckbox = (e: any) => {
    let statusespayload: any = {};
    if (e.target.checked) {
      statusespayload.types = status_type.data.map((s: any) => s.id);
    } else {
      statusespayload.types = [];
    }
    setselectedTask(statusespayload.types);
  };

  const storefilterValues = (e: any) => {
    let statusespayload: any = {};
    statusespayload.types = selectedTask;
    statusespayload.servicecall_statuses = selectedtodoStatus;
    statusespayload.statuses = selectedTaskStatus;
    statusespayload.page = 1;
    let latestPayload = Object.assign({}, todopayload, statusespayload);
    setTodoPayload(latestPayload);
  };

  const resetSelectedItems = (value: any) => {
    let resetArray = ["type", "pservicecall_status", "task_status"];
    if (resetArray.includes("type")) {
      setselectedTask(todopayload.types);
    }
    if (resetArray.includes("pservicecall_status")) {
      setselectedtodoStatus(todopayload.servicecall_statuses);
    }
    if (resetArray.includes("task_status")) {
      setselectedTaskStatus(todopayload.statuses);
    }
  };

  /*Todo type filter */
  const status_type = {
    data: [
      { id: "task", type_id: 1, type: "task", title: "Task", name: "Task" },
      {
        id: "ticket",
        type_id: 2,
        type: "ticket",
        title: "Ticket",
        name: "Ticket",
      },
      {
        id: "servicecall",
        type_id: 3,
        type: "serviceCall",
        title: AUTOTASK_ENTITY.ServiceCall.title,
        name: AUTOTASK_ENTITY.ServiceCall.name,
      },
    ],
  };

  /*Planning type filter */
  let status_type_planning: any;
  if (generalSetting?.show_appointments) {
    status_type_planning = {
      data: [
        { id: "task", type_id: 1, type: "task", title: "Task", name: "Task" },
        {
          id: "ticket",
          type_id: 2,
          type: "ticket",
          title: "Ticket",
          name: "Ticket",
        },
        {
          id: "appointments",
          type_id: 4,
          type: "appointments",
          title: "Appointment",
          name: "Appointment",
        },
        // { "id": 'serviceCall', "type_id": 3, "type": "serviceCall", "title": "Todo" , "name":"Todo" },
      ],
    };
  } else {
    status_type_planning = {
      data: [
        { id: "task", type_id: 1, type: "task", title: "Task", name: "Task" },
        {
          id: "ticket",
          type_id: 2,
          type: "ticket",
          title: "Ticket",
          name: "Ticket",
        },
        // { "id": 'serviceCall', "type_id": 3, "type": "serviceCall", "title": "Todo" , "name":"Todo" },
      ],
    };
  }
  const statustype = generalSetting?.default_todofilter;

  useEffect(() => {
    if (
      !isfetchgeneralsetting &&
      !isfetchplanbucketlayoutandfilters &&
      !isfetchstatus &&
      !isfetchservicecallstatus
    ) {
      let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencesplanning = savedPreferences?.planning_filter || planbucketlayoutandfilters?.task_list_filter;
      const hasPlanningFilter = savedPreferencesplanning
        ? Object.keys(savedPreferencesplanning).length
        : 0;
      if (hasPlanningFilter === 0) {
        let statusespayload: any = {
          resources: [],
          projects: [],
          departments: [],
          companies: [],
          workgroups: [],
          start_date: null,
          end_date: null,
          roles: [],
          project_leads: [],
          queues: [],
          sla: [],
          contract_category: [],
          skills: [],
          lineOfBusiness: [],
          page: 1,
          items_per_page: 5,
          statuses: [],
          servicecall_statuses: [],
          types: [],
          priority: [],
          task_categories: [],
          ticket_categories: [],
          ticket_types: [],
          show_more_less: false,
          search: "",
          sort: "",
        };
        settodosorticon("/media/icons/duotune/extra/sort-up.svg");
        setselectedsortMenu("");
        statusespayload.types = statustype;
        statusespayload.statuses = todo_status
          ?.filter((s: any) => parseInt(s.value) != 5 && s.isActive == true)
          ?.map((x: any) => parseInt(x.id));
        statusespayload.servicecall_statuses = todo_status_serviceCall
          ?.filter((s: any) => parseInt(s.value) != 2 && s.isActive == true)
          ?.map((x: any) => parseInt(x.id));
        let latestPayload = Object.assign({}, todopayload, statusespayload);
        setselectedTask(statusespayload.types);
        setselectedTaskStatus(statusespayload.statuses);
        setselectedtodoStatus(statusespayload.servicecall_statuses);
        setTodoPayload(latestPayload);
      }
    }
  }, [
    isfetchstatus,
    isfetchplanbucketlayoutandfilters,
    isfetchservicecallstatus,
    isfetchgeneralsetting
  ]);

  const handleTypeChange = (e: any) => {
    let typepayload: any = {};
    if (e.target.value !== "All") {
      if (e.target.checked) {
        typepayload.types = [...selectedTask, e.target.value];
      } else {
        typepayload.types = selectedTask.filter(
          (item: any) => item !== e.target.value
        );
      }
      setselectedTask(typepayload.types);
    } else {
      toggleDropdown("", "no");
      typepayload.types = [];
      typepayload.statuses = [];
      typepayload.servicecall_statuses = [];
      setselectedTask([]);
      setselectedTaskStatus([]);
      setselectedtodoStatus([]);
      let latestPayload = Object.assign({}, todopayload, typepayload);
      setTodoPayload(latestPayload);
    }
  };

  const handleAllClear = () => {
    let clearpayload: any = {};
    let ar: any = [];
    let filteredData = toplanlist?.taskList;
    clearpayload.statuses = [];
    clearpayload.page = 1;
    clearpayload.types = [];
    clearpayload.servicecall_statuses = [];
    setselectedTask([]);
    setselectedTaskStatus([]);
    setselectedtodoStatus([]);
    setSorttododata(filteredData);
    dispatch(storeClearAllFilter(true));
    dispatch(storeCapacityProjectIds(ar));
    dispatch(storeCapacityRoleIds(ar));
    dispatch(storeCapacityPeopleIds(ar));
    dispatch(storeCapacityCompanyIds(ar));
    dispatch(storeCapacityDepartmentIds(ar));
    dispatch(storeCapacityprojectLeadsids(ar));
    dispatch(storeCapacityqueuesids(ar));
    dispatch(storeCapacityprioritiesids(ar));
    dispatch(storeCapacityticketCategoryids(ar));
    dispatch(storeCapacityticketTypeids(ar));
    dispatch(storeCapacitytaskCategoryids(ar));
    dispatch(storeCapacityslasids(ar));
    dispatch(storeCapacitycontractcategoryids(ar));
    dispatch(storeCapacityskillids(ar));
    let latestPayload = Object.assign({}, todopayload, clearpayload);
    setTodoPayload(latestPayload);
    toggleDropdown("", "no");
  };

  /*End Todo type filter */

  const sortTodoData = (e: any) => {
    let sortpayload: any = {};
    setselectedsortMenu("");
    setsorttodoWith(e.target.getAttribute("data-name"));
    if (e.target.getAttribute("data-name") == "duedate_asc") {
      settodosorticon("/media/icons/duotune/extra/sort-up.svg");
      setselectedsortMenu("Due date");
      sortpayload.sort = "dueDateTime";
      sortpayload.order = 1;
    } else if (e.target.getAttribute("data-name") == "duedate_desc") {
      settodosorticon("/media/icons/duotune/new-icons/sort-down.svg");
      setselectedsortMenu("Due date");
      sortpayload.sort = "dueDateTime";
      sortpayload.order = -1;
    } else if (e.target.getAttribute("data-name") == "company_asc") {
      settodosorticon("/media/icons/duotune/extra/sort-up.svg");
      setselectedsortMenu("Company");
      sortpayload.sort = "companyID";
      sortpayload.order = 1;
    } else if (e.target.getAttribute("data-name") == "company_desc") {
      settodosorticon("/media/icons/duotune/new-icons/sort-down.svg");
      setselectedsortMenu("Company");
      sortpayload.sort = "companyID";
      sortpayload.order = -1;
    } else if (e.target.getAttribute("data-name") == "status_asc") {
      sortpayload.sort = "status";
      sortpayload.order = 1;
      setselectedsortMenu("Status");
      settodosorticon("/media/icons/duotune/extra/sort-up.svg");
    } else if (e.target.getAttribute("data-name") == "status_desc") {
      settodosorticon("/media/icons/duotune/new-icons/sort-down.svg");
      setselectedsortMenu("Status");
      sortpayload.sort = "status";
      sortpayload.order = -1;
    } else {
      sortpayload.sort = "";
      sortpayload.order = "";
    }
    sortpayload.page = 1;
    let latestPayload = Object.assign({}, todopayload, sortpayload);
    setTodoPayload(latestPayload);
  };

  useEffect(() => {
    if (
      !isfetchgeneralsetting &&
      !isfetchlayoutandfilters &&
      !isfetchservicecallstatus
    ) {
      let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencesplanning = savedPreferences?.planning_filter || layoutandfilters?.planning_filter;
      if (Object.keys(savedPreferencesplanning || "")?.length === 0) {
        let todayDate = moment();
        let payloadL = JSON.parse(JSON.stringify(payload));
        let weekStart: any;
        let weekEnd: any;
        let start: any;
        let end: any;
        if (
          generalSetting?.planning_screen_view !== "" &&
          payloadL?.view_type === ""
        ) {
          weekStart = todayDate
            .clone()
            .startOf(generalSetting?.planning_screen_view);
          weekEnd = todayDate
            .clone()
            .endOf(generalSetting?.planning_screen_view);
          start = weekStart.format("YYYY-MM-DD");
          end = weekEnd.format("YYYY-MM-DD");
          if (generalSetting?.planning_screen_view === "week") {
            weekStart = todayDate
              .clone()
              .weekday(viewSettingsData?.week?.start || 1);
            weekEnd = todayDate
              .clone()
              .weekday(viewSettingsData?.week?.end || 5);
            if(viewSettingsData?.week?.start > viewSettingsData?.week?.end){
              weekEnd = todayDate
              .clone()
              .weekday(viewSettingsData?.week?.end || 5).add(1, 'week');
            }
            start = weekStart.format("YYYY-MM-DD");
            end = weekEnd.format("YYYY-MM-DD");
          }
          payloadL.start_date = start;
          payloadL.end_date = end;
          payloadL.view_type = generalSetting?.planning_screen_view;
        } else {
          start = payloadL.start_date;
          end = payloadL.end_date;
          payloadL.start_date = start;
          payloadL.end_date = end;
        }
        setcapacityType(payloadL.view_type);
        setPayload(payloadL);
      }
    }
  }, [
    isfetchgeneralsetting,
    isfetchlayoutandfilters,
    isfetchservicecallstatus,
    viewSettingsData,
  ]);

  useEffect(() => {
    if (!isfetchplanbucketlayoutandfilters) {
      let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencestodo = savedPreferences?.task_list_filter || planbucketlayoutandfilters?.task_list_filter;
      if (Object.keys(savedPreferencestodo || "")?.length > 0) {
        savedPreferencestodo.search = "";
        if (
          savedPreferencestodo.sort === "dueDateTime" &&
          savedPreferencestodo.order === 1
        ) {
          settodosorticon("/media/icons/duotune/extra/sort-up.svg");
          setselectedsortMenu("Due date");
        } else if (
          savedPreferencestodo.sort === "dueDateTime" &&
          savedPreferencestodo.order === -1
        ) {
          settodosorticon("/media/icons/duotune/new-icons/sort-down.svg");
          setselectedsortMenu("Due date");
        } else if (
          savedPreferencestodo.sort === "companyID" &&
          savedPreferencestodo.order === 1
        ) {
          settodosorticon("/media/icons/duotune/extra/sort-up.svg");
          setselectedsortMenu("Company");
        } else if (
          savedPreferencestodo.sort === "companyID" &&
          savedPreferencestodo.order === -1
        ) {
          settodosorticon("/media/icons/duotune/new-icons/sort-down.svg");
          setselectedsortMenu("Company");
        } else if (
          savedPreferencestodo.sort === "status" &&
          savedPreferencestodo.order === 1
        ) {
          setselectedsortMenu("Status");
          settodosorticon("/media/icons/duotune/extra/sort-up.svg");
        } else if (
          savedPreferencestodo.sort === "status" &&
          savedPreferencestodo.order === -1
        ) {
          settodosorticon("/media/icons/duotune/new-icons/sort-down.svg");
          setselectedsortMenu("Status");
        } else {
          settodosorticon("/media/icons/duotune/extra/sort-up.svg");
          setselectedsortMenu("");
        }
        setselectedTask(savedPreferencestodo.types);
        setselectedTaskStatus(savedPreferencestodo.statuses);
        setselectedtodoStatus(
          savedPreferencestodo.servicecall_statuses
        );
        setTodoPayload(savedPreferencestodo);
        setHasShowMore(
          savedPreferencestodo?.show_more_less || false
        );
      }
    }
  }, [isfetchplanbucketlayoutandfilters, isfetchgeneralsetting, isfetchservicecallstatus, planbucketlayoutandfilters?.task_list_filter]);

  useEffect(() => {
    if (!isfetchlayoutandfilters) {
      let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencesplanning = savedPreferences?.planning_filter || layoutandfilters?.planning_filter;
      let fromLastSaved = savedPreferences?.planning_filter ? true : false;
      if (savedPreferencesplanning?.showtodo === true) {
        settodoIsShown(savedPreferencesplanning?.showtodo);
        settodoclassName("todoShow");
        setToplanActive("#164387");
        setToPlanBtnText("text-white");
      } else {
        setToplanActive("");
        setToPlanBtnText("");
        settodoclassName("");
        settodoIsShown(false);
      }
      if (savedPreferencesplanning?.showcapacity == "d-none") {
        setspanclassName("");
        setIsShown(savedPreferencesplanning?.showcapacity);
        setBtnColor("");
        setBtnText("");
        setheightClassName("heightShow");
      } else {
        setIsShown("d-flex");
        setspanclassName("capacityShow");
        setheightClassName("heightShow");
        setBtnColor("#164387");
        setBtnText("text-white");
      }
      if (Object.keys(savedPreferencesplanning || "")?.length > 0) {
        setFilterEnable(false)
        if (savedPreferencesplanning?.sort_field) {
          savedPreferencesplanning?.sort_field != ""
            ? setSortActive("#164387")
            : setSortActive("");
          savedPreferencesplanning?.sort_field != ""
            ? setSortBtnText("text-white")
            : setSortBtnText("");
        }

        let start = payload.start_date;
        let end = payload.end_date;
        if (savedPreferencesplanning.view_type != "") {
          let todayDate = moment();
          if(fromLastSaved){
            todayDate = moment(savedPreferencesplanning.start_date)
          }
          let weekStart = todayDate
            .clone()
            .startOf(savedPreferencesplanning.view_type);
          let weekEnd = todayDate
            .clone()
            .endOf(savedPreferencesplanning.view_type);
          if (savedPreferencesplanning.view_type === "week") {
            const { start: weekStartDay, end: weekEndDay } = viewSettingsData?.week || {};
            weekStart = todayDate.clone()
            .endOf("week")
            .weekday(weekStartDay)

            if(weekStartDay > weekEndDay){
              weekEnd = todayDate.clone()
              .endOf("week")
              .weekday(weekEndDay)
              .add(1, 'week')
            }else{
              weekEnd = todayDate.clone()
                .endOf("week")
                .weekday(weekEndDay)
            }
          }
          start = weekStart.format("YYYY-MM-DD");
          end = weekEnd.format("YYYY-MM-DD");
          setcapacityType(savedPreferencesplanning.view_type);
        }
        savedPreferencesplanning.start_date = start;
        savedPreferencesplanning.end_date = end;
        if(fromLastSaved){
          setCurrentDate(savedPreferencesplanning.start_date)
        }
        let planning_payload = JSON.parse(
          JSON.stringify(savedPreferencesplanning)
        );
        if (generalSetting?.show_appointments == false) {
          planning_payload.type_ids = planning_payload?.type_ids?.filter(
            (item: any) => item !== "appointments"
          );
        }
        planning_payload.resources = planning_payload?.resources || [];
        planning_payload.projects = planning_payload?.projects || [];
        planning_payload.companies = planning_payload?.companies || [];
        planning_payload.departments = planning_payload?.departments;
        planning_payload.type_ids = planning_payload?.type_ids || [];
        planning_payload.servicecall_status_ids =
          planning_payload?.servicecall_status_ids || [];
        planning_payload.roles = planning_payload?.roles || [];
        planning_payload.queues_ids = planning_payload?.queues_ids || [];
        planning_payload.skills = planning_payload?.skills || [];
        planning_payload.lineOfBusiness =
          planning_payload?.lineOfBusiness || [];
        planning_payload.view_type = planning_payload?.view_type || "week";
        setPayload(planning_payload);
        setTimeout(() => {
          setFilterEnable(true)
        }, 2000);
      }
    }else{
        dispatch(setispageLoad(true));
    }
  }, [isfetchlayoutandfilters, isfetchgeneralsetting, isfetchservicecallstatus, layoutandfilters?.planning_filter]);

  useEffect(() => {
    if (!isfetchresourcesetting) {
      setResourceList(true)
    }
  }, [isfetchresourcesetting])

  useEffect(() => {
    if (!isfetchgeneralsetting && !isfetchlayoutandfilters && !isfetchresourcesetting) {
      dispatch(setispageLoad(true));
      if (payload.sort_field || resourceFilter) {
        setResourceList(true);
      }
      if (payload.view_type === "day" || payload.view_type === "week") {
        setplanningList(true);
      } else {
        setmonthquery(true);
      }
      saveToLocalStorage('planning_layout', {
        planning_filter: Object.assign(payload, {
          showtodo: istodoShown,
          showcapacity: isShown,
          showView: isviewShown,
        }),
        task_list_filter: todopayload,
      })
    }
    
  }, [payload, isfetchresourcesetting]);


  //UseEffect to genralte a new Query 
  useEffect(() => {
    if (!isfetchplanbucketlayoutandfilters && !isfetchgeneralsetting) {
      toplanrefetch();
      saveToLocalStorage('planning_layout', {
        planning_filter: Object.assign(payload, {
          showtodo: istodoShown,
          showcapacity: isShown,
          showView: isviewShown,
        }),
        task_list_filter: todopayload,
      })
    }
  }, [todopayload]);

  useEffect(() => {
    if (!istoplanfetch) {
      setSorttododata(toplanlist?.taskList);
    }
  }, [istoplanfetch]);

  const [openDropdown, setOpenDropdown] = useState(null);
  const toggleDropdown = (id: any, reset = "true") => {
    if (reset === "true") {
      resetSelectedItems(id);
    }
    setOpenDropdown(openDropdown === id ? null : id);
  };
  const { _id, showTooltipVisibility } = currentUser.data?.user;
  const { data: tooltipData } = useQuery(
    "tooltipData",
    () => {
      return getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const steps = tooltipData?.filter(
    (v) => v.type === "tour" && v.page === "Planning"
  );

  const [enabled, setEnabled] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);

  const tooltipVisibility = {
    showTooltipVisibility: {
      Planning: false,
    },
  };

  const { data: loggedUser } = useQuery(
    'logged-user',
    async () => {
      return await getCurrentUserDetails(_id)
    },
    {
      cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
    }
  )
  const onExit = async () => {
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };

  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
   if(responseAvailability?.allResource?.length > 0 && !isFetchingAvailability){
       setTimeout(() => {
         setIsReady(true)
       }, 1000)
     }
  }, [isFetchingAvailability])
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="resources"
          element={
            <>
              <Loading
                isLoading={isLoadingMonthYear}
                isHideSpinner={true}
                className="splash-screen"
              />
              {ids?.ispageLoad && (
                <>
                  <Loading
                    isLoading={
                      istoplanfetch ||
                      isFetchingPlanning ||
                      isFetchingAvailability ||
                      isfetchgeneralsetting ||
                      isfetchresourcesetting ||
                      isfetchservicecallstatus ||
                      isfetchlayoutandfilters ||
                      isFetchingMonthdata
                    }
                    isHideSpinner={true}
                    className="splash-screen"
                  />
                </>
              )}
              {loggedUser && loggedUser?.showTooltipVisibility?.Planning !== false &&
              responseAvailability?.allResource?.length > 0 && !isFetchingAvailability &&
              isReady &&
                 (
                  <>
                    <Steps
                      enabled={enabled}
                      initialStep={initialStep}
                      steps={steps?.length > 0 ? steps : []}
                      onExit={onExit}
                      options={{
                        showStepNumbers: true,
                        showButtons: true,
                        hidePrev: true,
                        scrollToElement: true,
                        showBullets: true,
                        showProgress: true,
                        exitOnOverlayClick: false,
                        disableInteraction: true,
                      }}
                    />
                  </>
                )}
              <div
                className={`d-flex flex-column notifyHeight ${spanclassName}`}
              >
                <div className="p-main h-100">
                  <PlanningView
                    viewSettingsData={['day','week'].includes(payload?.view_type) ? viewSettingsData[payload?.view_type] : []}
                    selectedId={selectedId}
                    availability={responsePlanning?.formattedData}
                    resource_data={responseAvailability?.allResource}
                    lockedResourceData={
                      responseAvailability?.allResource
                        ?.filter((i) => !i.has_license && !i?.resource_only)
                        ?.map((i) => i.autotask_id) || []
                    }
                    handleclick={showcomponent}
                    refreshAvailability={refreshAvailability}
                    sortData={sortData}
                    refetchPlanning={refetchPlanning}
                    isFetchingAvailability={isFetchingAvailability}
                    isFetchingPlanning={isFetchingPlanning}
                    activebtn={btnColor}
                    activetextcolor={activetextcolor}
                    toplanActive={toplanActive}
                    toplanactivetextcolor={toplanactivetextcolor}
                    viewBtnbackground={viewBtnbackground}
                    Viewbtncolor={Viewbtncolor}
                    planningCapacityView={planningCapacityFilter}
                    toplanlist={sorttododata}
                    showtodo_onclick={showtodocomponent}
                    showviewcomponent={showviewcomponent}
                    todoclassName={todoclassName}
                    viewclassName={viewclassName}
                    sortActive={sortActive}
                    sortactivetextcolor={sortactivetextcolor}
                    toplanrefetch={toplanrefetch}
                    sortTooltip={sortTooltip}
                    sortIcon={sortIcon}
                    handleHeaderChange={handleHeaderView}
                    resource_settings={resource_settings}
                    general_settings={generalSetting}
                    istoplanFetching={istoplanfetch}
                    isfetchinggeneralsetting={isfetchgeneralsetting}
                    filterArray={{
                      peoples: peoples,
                      isPeopleFetch: isPeopleFetch,
                      departments: departments,
                      isDepartmentFetch: isDepartmentFetch,
                      isCompanyFetch: isCompanyFetch,
                      companies: companies,
                      isProjectFetch: isProjectFetch,
                      projects: projects,
                      isLocationFetch: isLocationFetch,
                      locations: locations,
                      isSkillFetch: isSkillFetch,
                      skills: skills,
                      isRoleFetch: isRoleFetch,
                      roles: roles,
                      status_type: status_type_planning.data,
                      todo_status_serviceCall: todo_status_serviceCall,
                      isfetchservicecallstatus: isfetchservicecallstatus,
                      queues: queues,
                      isQueuesFetch: isQueuesFetch,
                      taskCategory: taskCategory,
                      isTaskCategoryFetch: isTaskCategoryFetch,
                      taskType: taskType,
                      ticketCategory: ticketCategory,
                      isTicketCategoryFetch: isTicketCategoryFetch,
                      ticketType: ticketType,
                      layoutandfilters: layoutandfilters,
                      isfetchlayoutandfilters: isfetchlayoutandfilters,
                      islineOfBusinessFetch: islineOfBusinessFetch,
                      lineOfBusiness: lineOfBusiness,
                      isPrioritiesFetch: isPrioritiesFetch,
                      priorities: priorities,
                      isSlasFetch: isSlasFetch,
                      slas: slas,
                      contractCategory: contractCategory,
                      isContractCategoryFetch: isContractCategoryFetch,
                      taskticketstatus: todo_status,
                      isfetchstatus: isfetchstatus,
                    }}
                    locations={location}
                    filterIds={Object.assign(payload, {
                      showtodo: istodoShown,
                      showcapacity: isShown,
                      showView: isviewShown,
                    })}
                    todopayload={todopayload}
                    syncdata={syncdata}
                    ticket_status={todo_status}
                    task_status={todo_status}
                    prioritylist={priorities?.data}
                    issueslist={issues?.data}
                    subIssueslist={subIssues?.data}
                    todo_status_serviceCall={todo_status_serviceCall}
                    worktype={worktype}
                    phase={phase}
                    pagination={toplanlist?.pagination}
                    dateHeaderCal={dateHeaderCal}
                    responseMonthdata={responseMonthdata?.data}
                    responseYeardata={responseMonthdata?.yearData}
                    refetchMonthdata={refetchMonthdata}
                    isloadwithfiltermonth={
                      payload?.resources?.length > 0 ||
                      payload?.projects?.length > 0 ||
                      payload?.companies?.length > 0 ||
                      payload?.departments?.length > 0 ||
                      payload?.type_ids?.length > 0 ||
                      payload?.servicecall_status_ids?.length > 0 ||
                      payload?.roles?.length > 0 ||
                      payload?.queues_ids?.length > 0 ||
                      payload?.skills?.length > 0 ||
                      payload?.lineOfBusiness?.length > 0
                    }
                    refreshtodo={refreshtodo}
                    offlineCount={offlineCount}
                    layoutAndFiltersData={layoutAndFiltersData}
                    selectActiveLayout={selectActiveLayout}
                    setIsPopupOpen={setIsPopupOpen}
                    isFetchingMonthdata={isFetchingMonthdata}
                    planningTask={planningTask}
                    setPlanningTask={setPlanningTask}
                    monthCatchPayload={payload}
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown}
                    toggleDropdown={toggleDropdown}
                    initial_sync_completed_or_not={
                      initial_sync_completed_or_not
                    }
                    isLoadingView={isLoadingView}
                    refetchLayouts={refetchLayouts}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    monthYearDataResponse={monthYearDataResponse}
                  />
                  <TodoPlan
                    componentName={"Planning"}
                    toplanlist={sorttododata}
                    pagination={toplanlist?.pagination}
                    isFetching={istoplanfetch}
                    showtodo_onclick={showtodocomponent}
                    inputHandler={inputHandler}
                    handleTodoStatusChange={handleTodoStatusChange}
                    selectedStatus={selectedTaskStatus}
                    todo_status={todo_status}
                    todo_status_serviceCall={todo_status_serviceCall}
                    filteredData={sorttododata}
                    general_settings={generalSetting}
                    sortTodoData={sortTodoData}
                    selectedServicecallStatus={selectedtodoStatus}
                    selectedsortCompany={selectedsortMenu}
                    selectedsortMenu={selectedsortMenu}
                    todosorticon={todosorticon}
                    status_type={status_type}
                    handleTypeChange={handleTypeChange}
                    handleServiceCallStatusChange={
                      handleServiceCallStatusChange
                    }
                    filterIds={todopayload}
                    prioritylist={priorities?.data || []}
                    queues={queues?.data || []}
                    filterCapacityArray={{
                      peoples: peoples,
                      isPeopleFetch: isPeopleFetch,
                      departments: departments,
                      isDepartmentFetch: isDepartmentFetch,
                      isCompanyFetch: isCompanyFetch,
                      companies: companies,
                      isProjectFetch: isProjectFetch,
                      projects: projects,
                      isLocationFetch: isLocationFetch,
                      locations: locations,
                      isSkillFetch: isSkillFetch,
                      skills: skills,
                      isRoleFetch: isRoleFetch,
                      roles: roles,
                      status_type: status_type_planning.data,
                      todo_status_serviceCall: todo_status_serviceCall,
                      isfetchservicecallstatus: isfetchservicecallstatus,
                      isPrioritiesFetch: isPrioritiesFetch,
                      priorities: priorities,
                      isTicketCategoryFetch: isTicketCategoryFetch,
                      ticketCategory: ticketCategory,
                      isTicketTypeFetch: isTicketTypeFetch,
                      ticketType: ticketType,
                      taskCategory: taskCategory,
                      isTaskCategoryFetch: isTaskCategoryFetch,
                      isSlasFetch: isSlasFetch,
                      slas: slas,
                      contractCategory: contractCategory,
                      isContractCategoryFetch: isContractCategoryFetch,
                      queues: queues,
                      isQueuesFetch: isQueuesFetch,
                      layoutandfilters: planbucketlayoutandfilters,
                      isfetchlayoutandfilters: isfetchplanbucketlayoutandfilters,
                      islineOfBusinessFetch: islineOfBusinessFetch,
                      lineOfBusiness: lineOfBusiness,
                    }}
                    handleAllClear={handleAllClear}
                    peoples={peoples}
                    linkIcon="/media/icons/duotune/new-icons/link-click.svg"
                    updatePage={updatePage}
                    changeitemperpage={changeitemperpage}
                    offlineCount={offlineCount}
                    refreshtodo={refreshtodo}
                    hasShowMore={hasShowMore}
                    setHasShowMore={setHasShowMore}
                    isfetchinggeneralsetting={isfetchgeneralsetting}
                    handleAllSelectCheckbox={handleAllSelectCheckbox}
                    handleAllTypeSelectCheckbox={handleAllTypeSelectCheckbox}
                    handleAllServiceCallChecked={handleAllServiceCallChecked}
                    selectedTask={selectedTask}
                    planningTask={planningTask}
                    setPlanningTask={setPlanningTask}
                    storefilterValues={storefilterValues}
                    openDropdown={openDropdown}
                    setOpenDropdown={setOpenDropdown}
                    toggleDropdown={toggleDropdown}
                    layoutAndFiltersData={planbucketlayoutAndFiltersData}
                    selectActiveLayout={selectActiveLayout}
                    refetchLayouts={refetchplanbucketLayouts}
                  />

                  <ViewSettings
                    showviewcomponent={showviewcomponent}
                    screen_view={payload?.view_type}
                    isviewShown={isviewShown}
                  />
                </div>
                <PlanningCapacityGraph
                  availability={responseAvailability}
                  isgraphShown={isShown}
                  plannedTask={plannedTaskList}
                  freeTask={freeTaskList}
                  capacityTask={capacityTaskList}
                  axisCategory={axisCategoryList}
                  overbookedTask={overBookedTaskList}
                  handleday={handleDailyFilter}
                  handleweek={handleWeeklyFilter}
                  handlemonth={handleMonthlyFilter}
                  istodoShown={istodoShown}
                  todoclassName={todoclassName}
                  viewclassName={viewclassName}
                  closeCapacityGraph={showcomponent}
                  capacityLabel={capacityLabelVal}
                  confirmLabel={confirmLabelVal}
                  availableCapacityLabel={availableCapacityLabelVal}
                  overbookLabel={overbookLabelVal}
                  confirmIcon={confirmIconPath}
                  capacityIcon={capacityIconPath}
                  availableCapacityIcon={availableCapacityIconPath}
                  overbookIcon={overbookIconPath}
                  isCapacitydailytype={isCapacitydailytype}
                  timelineView={payload.view_type}
                  filterCapacityArray={{
                    peoples: peoples,
                    isPeopleFetch: isPeopleFetch,
                    departments: departments,
                    isDepartmentFetch: isDepartmentFetch,
                  }}
                  monthYearDataResponse={monthYearDataResponse}
                />
              </div>
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/" />} />
    </Routes>
  );
};

export default PlanningPage;