import { FC, useEffect, useState } from "react";

import { KTSVG } from "../../../../../_metronic/helpers";
import { setcallApifortodofilter, storeCapacityCompanyIds, storeCapacityDepartmentIds, storeCapacityPeopleIds, storeCapacityProjectIds, storeCapacityRoleIds, storeCapacitycontractcategoryids, storeCapacitylineOfBusinessIds, storeCapacityprioritiesids, storeCapacityprojectLeadsids, storeCapacityqueuesids, storeCapacityskillids, storeCapacityslasids, storeCapacitytaskCategoryids, storeCapacityticketCategoryids, storeCapacityticketTypeids, storeClearAllFilter } from "../../../../counterSlice";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { CapacityDropDown } from "./CapacityDropDown";
import { loadFromLocalStorage } from "../../../../helpers/latest_selection";

type Props = {
  filterCapacityArray?: any
  general_settings?:any
  toggleDropdown?:any
  openDropdown?:any
  setOpenDropdown?:any
}
const FilterCapacity: FC<Props> = ({ filterCapacityArray, general_settings,toggleDropdown, openDropdown }) => {
  
  const dispatch = useAppDispatch()
  const count = useAppSelector((state) => state.counter);
  const [currentClick, setcurrentClick] = useState('');
  const [showproject, setshowproject] = useState('d-none');
  const [showrole, setshowrole] = useState('d-none');
  const [showpeople, setshowpeople] = useState('d-none');
  const [showdepartment, setshowdepartment] = useState('d-none');
  
  const [showcompany, setshowcompany] = useState('d-none');
  const [Projectid, setProjectid] = useState<any>([]);
  const [Roleid, setRoleid] = useState<any>([]);
  const [ProjectName, setProjectName] = useState<any>([]);
  const [RoleName, setRoleName] = useState<any>([]);
  const [Peopletid, setPeopletid] = useState<any>(Array);
  const [PeopletName, setPeopletName] = useState<any>([]);
  const [Departmentid, setDepartmentid] = useState<any>(Array);
  const [DepartmentName, setDepartmentName] = useState<any>([]);
  const [Companyid, setCompanyid] = useState<any>(Array);
  const [CompanyName, setCompanyName] = useState<any>([]);
  const [projectinputText, setprojectinputText] = useState("");
  const [roleinputText, setroleinputText] = useState("");
  const [peopleinputText, setpeopleinputText] = useState("");
  const [departmentinputText, setdepartmentinputText] = useState("");
  const [companyinputText, setcompanyinputText] = useState("");
  //project lead
  const [showprojectlead, setshowprojectlead] = useState('d-none');
  const [projectleadinputText, setprojectleadinputText] = useState("");
  const [projectleadid, setprojectleadid] = useState<any>(Array);
  const [projectleadName, setprojectleadName] = useState<any>([]);
  //queues
  const [showqueues, setshowqueues] = useState('d-none');
  const [queuesinputText, setqueuesinputText] = useState("");
  const [queuesid, setqueuesid] = useState<any>(Array);
  const [queuesName, setqueuesName] = useState<any>([]);
  //priorities
  const [showpriorities, setshowpriorities] = useState('d-none');
  const [prioritiesinputText, setprioritiesinputText] = useState("");
  const [prioritiesid, setprioritiesid] = useState<any>(Array);
  const [prioritiesName, setprioritiesName] = useState<any>([]);
  //slas
  const [showslas, setshowslas] = useState('d-none');
  const [slasinputText, setslasinputText] = useState("");
  const [slasid, setslasid] = useState<any>(Array);
  const [slasName, setslasName] = useState<any>([]);
  //ticketcategory
  const [showticketcategory, setshowticketcategory] = useState('d-none');
  const [ticketcategoryinputText, setticketcategoryinputText] = useState("");
  const [ticketcategoryid, setticketcategoryid] = useState<any>(Array);
  const [ticketcategoryName, setticketcategoryName] = useState<any>([]);
  //tickettype
  const [showtickettype, setshowtickettype] = useState('d-none');
  const [tickettypeinputText, settickettypeinputText] = useState("");
  const [tickettypeid, settickettypeid] = useState<any>(Array);
  const [tickettypeName, settickettypeName] = useState<any>([]);
  //taskcategory
  const [showtaskcategory, setshowtaskcategory] = useState('d-none');
  const [taskcategoryinputText, settaskcategoryinputText] = useState("");
  const [taskcategoryid, settaskcategoryid] = useState<any>(Array);
  const [taskcategoryName, settaskcategoryName] = useState<any>([]);
  //contractcategory
  const [showcontractcategory, setshowcontractcategory] = useState('d-none');
  const [contractcategoryinputText, setcontractcategoryinputText] = useState("");
  const [contractcategoryid, setcontractcategoryid] = useState<any>(Array);
  const [contractcategoryName, setcontractcategoryName] = useState<any>([]);
  //skills
  const [showskill, setshowskill] = useState('d-none');
  const [skillinputText, setskillinputText] = useState("");
  const [Skillid, setSkillid] = useState<any>(Array);
  const [SkillName, setSkillName] = useState<any>([]);

  //line of business
  const [showlineOfBusiness, setshowlineOfBusiness] = useState('d-none');
  const [lineOfBusinessinputText, setlineOfBusinessinputText] = useState("");
  const [lineOfBusinessid, setlineOfBusinessid] = useState<any>(Array);
  const [lineOfBusinessName, setlineOfBusinessName] = useState<any>([]);

  const hideSelection = (e: any) => {
    dispatch(setcallApifortodofilter(true));
    const ar: any = [];
    const fieldMap = {
        'cProject': { idSetter: setProjectid, nameSetter: setProjectName, storeAction: storeCapacityProjectIds, hideSetter: setshowproject, inputSetter: setprojectinputText },
        'cRole': { idSetter: setRoleid, nameSetter: setRoleName, storeAction: storeCapacityRoleIds, hideSetter: setshowrole, inputSetter: setroleinputText },
        'cPeople': { idSetter: setPeopletid, nameSetter: setPeopletName, storeAction: storeCapacityPeopleIds, hideSetter: setshowpeople, inputSetter: setpeopleinputText },
        'cDepartment': { idSetter: setDepartmentid, nameSetter: setDepartmentName, storeAction: storeCapacityDepartmentIds, hideSetter: setshowdepartment, inputSetter: setdepartmentinputText },
        'cCompany': { idSetter: setCompanyid, nameSetter: setCompanyName, storeAction: storeCapacityCompanyIds, hideSetter: setshowcompany, inputSetter: setcompanyinputText },
        'cprojectlead': { idSetter: setprojectleadid, nameSetter: setprojectleadName, storeAction: storeCapacityprojectLeadsids, hideSetter: setshowprojectlead, inputSetter: setprojectleadinputText },
        'cqueues': { idSetter: setqueuesid, nameSetter: setqueuesName, storeAction: storeCapacityqueuesids, hideSetter: setshowqueues, inputSetter: setqueuesinputText },
        'cPriorities': { idSetter: setprioritiesid, nameSetter: setprioritiesName, storeAction: storeCapacityprioritiesids, hideSetter: setshowpriorities, inputSetter: setprioritiesinputText },
        'cSLAs': { idSetter: setslasid, nameSetter: setslasName, storeAction: storeCapacityslasids, hideSetter: setshowslas, inputSetter: setslasinputText },
        'cTicket category': { idSetter: setticketcategoryid, nameSetter: setticketcategoryName, storeAction: storeCapacityticketCategoryids, hideSetter: setshowticketcategory, inputSetter: setticketcategoryinputText },
        'cTicket type': { idSetter: settickettypeid, nameSetter: settickettypeName, storeAction: storeCapacityticketTypeids, hideSetter: setshowtickettype, inputSetter: settickettypeinputText },
        'cTask category': { idSetter: settaskcategoryid, nameSetter: settaskcategoryName, storeAction: storeCapacitytaskCategoryids, hideSetter: setshowtaskcategory, inputSetter: settaskcategoryinputText },
        'cContract category': { idSetter: setcontractcategoryid, nameSetter: setcontractcategoryName, storeAction: storeCapacitycontractcategoryids, hideSetter: setshowcontractcategory, inputSetter: setcontractcategoryinputText },
        'cSkills': { idSetter: setSkillid, nameSetter: setSkillName, storeAction: storeCapacityskillids, hideSetter: setshowskill, inputSetter: setskillinputText },
        'cLineOfBusiness': { idSetter: setlineOfBusinessid, nameSetter: setlineOfBusinessName, storeAction: storeCapacitylineOfBusinessIds, hideSetter: setshowlineOfBusiness, inputSetter: setlineOfBusinessinputText },
    };

    const field = fieldMap[e.target.value];
    if (field) {
        field.idSetter(ar);
        field.nameSetter(ar);
        dispatch(field.storeAction(ar));
        field.hideSetter('d-none');
        field.inputSetter("");
    } else {
        clearAllFilter(true);
    }
  };


  const clearAllFilter = (value:any) => {
    dispatch(setcallApifortodofilter(value))
    let ar: any = [];
    setProjectid([]);
    setProjectName([]);
    dispatch(storeCapacityProjectIds(ar))
    setshowproject('d-none');
    setprojectinputText("");

    setRoleid([]);
    setRoleName([]);
    dispatch(storeCapacityRoleIds(ar))
    setshowrole('d-none');
    setroleinputText("");

    setshowpeople('d-none');
    setPeopletid(ar);
    setPeopletName(ar)
    dispatch(storeCapacityPeopleIds(ar))
    setpeopleinputText("");

    setDepartmentid(ar);
    setDepartmentName(ar);
    dispatch(storeCapacityDepartmentIds(ar))
    setshowdepartment('d-none');
    setdepartmentinputText("");

    setCompanyid(ar);
    setCompanyName(ar)
    dispatch(storeCapacityCompanyIds(ar))
    setshowcompany('d-none');
    setcompanyinputText("");

    setprojectleadid(ar);
    setprojectleadName(ar)
    dispatch(storeCapacityprojectLeadsids(ar))
    setshowprojectlead('d-none');
    setprojectleadinputText("");

    setqueuesid(ar);
    setqueuesName(ar)
    dispatch(storeCapacityqueuesids(ar))
    setshowqueues('d-none');
    setqueuesinputText("");

    setprioritiesid(ar);
    setprioritiesName(ar)
    dispatch(storeCapacityprioritiesids(ar))
    setshowpriorities('d-none');
    setprioritiesinputText("");

    setticketcategoryid(ar);
    setticketcategoryName(ar)
    dispatch(storeCapacityticketCategoryids(ar))
    setshowticketcategory('d-none');
    setticketcategoryinputText("");

    settickettypeid(ar);
    settickettypeName(ar)
    dispatch(storeCapacityticketTypeids(ar))
    setshowtickettype('d-none');
    settickettypeinputText("");

    settaskcategoryid(ar);
    settaskcategoryName(ar)
    dispatch(storeCapacitytaskCategoryids(ar))
    setshowtaskcategory('d-none');
    settaskcategoryinputText("");

    setslasid(ar);
    setslasName(ar)
    dispatch(storeCapacityslasids(ar))
    setshowslas('d-none');
    setslasinputText("");

    setcontractcategoryid(ar);
    setcontractcategoryName(ar)
    dispatch(storeCapacitycontractcategoryids(ar))
    setshowcontractcategory('d-none');
    setcontractcategoryinputText("");

    setSkillid(ar);
    setSkillName(ar);
    dispatch(storeCapacityskillids(ar));
    setshowskill('d-none');
    setskillinputText("");

    setlineOfBusinessid(ar);
    setlineOfBusinessName(ar);
    dispatch(storeCapacitylineOfBusinessIds(ar))
    setshowlineOfBusiness('d-none');
    setlineOfBusinessinputText("");
  }

  const storeFilterValue = (value: any) => {
    const filterMap: { [key: string]: { id: any; action: any } } = {
        'cProject': { id: Projectid, action: storeCapacityProjectIds },
        'cRole': { id: Roleid, action: storeCapacityRoleIds },
        'cPeople': { id: Peopletid, action: storeCapacityPeopleIds },
        'cCompany': { id: Companyid, action: storeCapacityCompanyIds },
        'cDepartment': { id: Departmentid, action: storeCapacityDepartmentIds },
        'cprojectlead': { id: projectleadid, action: storeCapacityprojectLeadsids },
        'cqueues': { id: queuesid, action: storeCapacityqueuesids },
        'cPriorities': { id: prioritiesid, action: storeCapacityprioritiesids },
        'cTicket type': { id: tickettypeid, action: storeCapacityticketTypeids },
        'cTicket category': { id: ticketcategoryid, action: storeCapacityticketCategoryids },
        'cTask category': { id: taskcategoryid, action: storeCapacitytaskCategoryids },
        'cSLAs': { id: slasid, action: storeCapacityslasids },
        'cContract category': { id: contractcategoryid, action: storeCapacitycontractcategoryids },
        'cSkills': { id: Skillid, action: storeCapacityskillids },
        'cLineOfBusiness': { id: lineOfBusinessid, action: storeCapacitylineOfBusinessIds }
    };

    Object.keys(filterMap).forEach(key => {
        if (value === key || value === "all") {
            const { id, action } = filterMap[key];
            dispatch(action(id));
        }
    });
  };


  const resetSelectedItems = (value: any) => {
      let resetArray = ['cProject', 'cRole' , 'cPeople', 'cCompany', 'cDepartment', 'cprojectlead', 'cqueues', 'cPriorities', 'cTicket type', 'cTicket category', 'cTask category', 'cSLAs', 'cContract category', 'cSkills', 'cLineOfBusiness'];
      if(resetArray.includes('cProject')){
        setProjectid(count.project_capacity_ids)
      }
      if(resetArray.includes('cRole')){
        setRoleid(count.role_capacity_ids)
      }
      if(resetArray.includes('cPeople')){
        setPeopletid(count.people_capacity_ids)
      }
      if(resetArray.includes('cCompany')){
        setCompanyid(count.company_capacity_ids)
      }
      if(resetArray.includes('cDepartment')){
        setDepartmentid(count.department_capacity_ids)
      }
      if(resetArray.includes('cprojectlead')){
        setprojectleadid(count.project_leads_capacity_ids)
      }
      if(resetArray.includes('cqueues')){
        setqueuesid(count.queues_capacity_ids)
      }
      if(resetArray.includes('cPriorities')){
        setprioritiesid(count.priorities_capacity_ids)
      }
      if(resetArray.includes('cTicket type')){
        settickettypeid(count.ticketType_capacity_ids)
      }
      if(resetArray.includes('cTicket category')){
        setticketcategoryid(count.ticketCategory_capacity_ids)
      }
      if(resetArray.includes('cTask category')){
        settaskcategoryid(count.taskCategory_capacity_ids)
      }
      if(resetArray.includes('cSLAs')){
        setslasid(count.slas_capacity_ids)
      }
      if(resetArray.includes('cContract category')){
        setcontractcategoryid(count.contract_category_ids)
      }
      if(resetArray.includes('cSkills')){
        setSkillid(count.skill_capacity_ids)
      }
      if(resetArray.includes('cLineOfBusiness')){
        setlineOfBusinessid(count.lineOfBusiness_capacity_ids)
      }
  }

  const ChangeFilter = (e: any) => {
    dispatch(setcallApifortodofilter(true))
    if (e.target.getAttribute('data-name') === 'project') {
      setshowproject('');
      setcurrentClick('Project');
      toggleDropdown("cProject")
    } else if (e.target.getAttribute('data-name') === 'role') {
      setshowrole('');
      setcurrentClick('Role');
      toggleDropdown("cRole")
    } else if (e.target.getAttribute('data-name') === 'people') {
      setshowpeople('');
      setcurrentClick('People');
      toggleDropdown("cPeople")
    } else if (e.target.getAttribute('data-name') === 'department') {
      setshowdepartment('');
      setcurrentClick('Department');
      toggleDropdown("cDepartment")
    } else if (e.target.getAttribute('data-name') === 'projectlead') {
      setshowprojectlead('');
      setcurrentClick('projectlead');
      toggleDropdown("cprojectlead")
    } else if (e.target.getAttribute('data-name') === 'queues') {
      setshowqueues('');
      setcurrentClick('queues');
      toggleDropdown("cqueues")
    } else if (e.target.getAttribute('data-name') === 'priorities') {
      setshowpriorities('');
      setcurrentClick('Priorities');
      toggleDropdown("cPriorities")
    } else if (e.target.getAttribute('data-name') === 'tickettype') {
      setshowtickettype('');
      setcurrentClick('Ticket type');
      toggleDropdown("cTicket type")
    } else if (e.target.getAttribute('data-name') === 'ticketcategory') {
      setshowticketcategory('');
      setcurrentClick('Ticket category');
      toggleDropdown("cTicket category")
    } else if (e.target.getAttribute('data-name') === 'taskcategory') {
      setshowtaskcategory('');
      setcurrentClick('Task category');
      toggleDropdown("cTask category")
    } else if (e.target.getAttribute('data-name') === 'slas') {
      setshowslas('');
      setcurrentClick('SLAs');
      toggleDropdown("cSLAs")
    } else if (e.target.getAttribute('data-name') === 'contractcategory') {
      setshowcontractcategory('');
      setcurrentClick('Contract category');
      toggleDropdown("cContract category")
    } else if (e.target.getAttribute('data-name') === 'Skills') {
      setshowskill('');
      setcurrentClick('Skills');
      toggleDropdown("cSkills")
    } else if (e.target.getAttribute('data-name') === 'LineOfBusiness') {
      setshowlineOfBusiness('');
      setcurrentClick('LineOfBusiness');
      toggleDropdown("cLineOfBusiness")
    } else {
      setshowcompany('');
      setcurrentClick('Company');
      toggleDropdown("cCompany")
    }
  }

  let inputHandler = (e: any) => {
    //convert input text to lower case

    var lowerCase = e.target.value.toLowerCase();
    if (e.target.id === 'cPeople-csearch') {
      setpeopleinputText(lowerCase);
    } else if (e.target.id === 'cCompany-csearch') {
      setcompanyinputText(lowerCase);
    } else if (e.target.id === 'cDepartment-csearch') {
      setdepartmentinputText(lowerCase);
    } else if (e.target.id === 'cProject-csearch') {
      setprojectinputText(lowerCase);
    } else if (e.target.id === 'cRole-csearch') {
      setroleinputText(lowerCase);
    } else if (e.target.id === 'cprojectlead-csearch') {
      setprojectleadinputText(lowerCase);
    } else if (e.target.id === 'cqueues-csearch') {
      setqueuesinputText(lowerCase);
    } else if (e.target.id === 'cPriorities-csearch') {
      setprioritiesinputText(lowerCase);
    } else if (e.target.id === 'cTicket type-csearch') {
      settickettypeinputText(lowerCase);
    } else if (e.target.id === 'cTicket category-csearch') {
      setticketcategoryinputText(lowerCase);
    } else if (e.target.id === 'cTask category-csearch') {
      settaskcategoryinputText(lowerCase);
    } else if (e.target.id === 'cSLAs-csearch') {
      setslasinputText(lowerCase);
    } else if (e.target.id === 'cContract category-csearch') {
      setcontractcategoryinputText(lowerCase);
    } else if (e.target.id === 'cSkills-csearch') {
      setskillinputText(lowerCase);
    } else if (e.target.id === 'cLineOfBusiness-csearch') {
      setlineOfBusinessinputText(lowerCase);
    }
  };

  const handleChange = (e: any) => {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;
    const dataId = e.target.getAttribute('data-id');

    const updateState = (setState: any, state: any) => {
        setState(isChecked ? [...state, value] : state.filter((item: any) => item !== value));
    };
    dispatch(setcallApifortodofilter(true));
    const filterActions: { [key: string]: { state: any; setState: any } } = {
        'cProject': { state: Projectid, setState: setProjectid },
        'cRole': { state: Roleid, setState: setRoleid },
        'cPeople': { state: Peopletid, setState: setPeopletid },
        'cCompany': { state: Companyid, setState: setCompanyid },
        'cDepartment': { state: Departmentid, setState: setDepartmentid },
        'cprojectlead': { state: projectleadid, setState: setprojectleadid },
        'cqueues': { state: queuesid, setState: setqueuesid },
        'cPriorities': { state: prioritiesid, setState: setprioritiesid },
        'cTicket type': { state: tickettypeid, setState: settickettypeid },
        'cTicket category': { state: ticketcategoryid, setState: setticketcategoryid },
        'cTask category': { state: taskcategoryid, setState: settaskcategoryid },
        'cSLAs': { state: slasid, setState: setslasid },
        'cContract category': { state: contractcategoryid, setState: setcontractcategoryid },
        'cSkills': { state: Skillid, setState: setSkillid },
        'cLineOfBusiness': { state: lineOfBusinessid, setState: setlineOfBusinessid },
    };

    const action = filterActions[dataId];
    if (action) {
        updateState(action.setState, action.state);
    }
  };


  const selectAll = (e: any) => {
    dispatch(setcallApifortodofilter(true));

    const id = e.target.id;
    const isChecked = e.target.checked;

    const selectAllOptions = (items: any[], filterFn: (item: any) => boolean) => 
        items?.filter(filterFn)?.map((x: any) => parseInt(x.id));

    const filterData: { [key: string]: { setter: Function, items: any[], filterFn: Function } } = {
        'cProject': {
            setter: setProjectid,
            items: filterCapacityArray?.projects,
            filterFn: (item: any) => item.isActive && !item.isCompleted
        },
        'cRole': {
            setter: setRoleid,
            items: filterCapacityArray?.roles,
            filterFn: (item: any) => item.isActive
        },
        'cPeople': {
            setter: setPeopletid,
            items: filterCapacityArray?.peoples,
            filterFn: (item: any) => item.isActive
        },
        'cCompany': {
            setter: setCompanyid,
            items: filterCapacityArray?.companies,
            filterFn: (item: any) => item.isActive
        },
        'cDepartment': {
            setter: setDepartmentid,
            items: filterCapacityArray?.departments?.items,
            filterFn: () => true // No filter, all items are selected
        },
        'cprojectlead': {
            setter: setprojectleadid,
            items: filterCapacityArray?.peoples,
            filterFn: (item: any) => item.isActive
        },
        'cPriorities': {
            setter: setprioritiesid,
            items: filterCapacityArray?.priorities?.data,
            filterFn: (item: any) => item.isActive
        },
        'cTicket type': {
            setter: settickettypeid,
            items: filterCapacityArray?.ticketType?.data,
            filterFn: (item: any) => item.isActive
        },
        'cTicket category': {
            setter: setticketcategoryid,
            items: filterCapacityArray?.ticketCategory?.data,
            filterFn: (item: any) => item.isActive
        },
        'cTask category': {
            setter: settaskcategoryid,
            items: filterCapacityArray?.taskCategory?.data,
            filterFn: (item: any) => item.isActive
        },
        'cSLAs': {
            setter: setslasid,
            items: filterCapacityArray?.slas?.data,
            filterFn: (item: any) => item.isActive
        },
        'cqueues': {
            setter: setqueuesid,
            items: filterCapacityArray?.queues?.data,
            filterFn: (item: any) => item.isActive
        },
        'cContract category': {
            setter: setcontractcategoryid,
            items: filterCapacityArray?.contractCategory?.data,
            filterFn: (item: any) => item.isActive
        },
        'cSkills': {
            setter: setSkillid,
            items: filterCapacityArray?.skills?.data,
            filterFn: (item: any) => item.isActive
        },
        'cLineOfBusiness': {
            setter: setlineOfBusinessid,
            items: filterCapacityArray?.lineOfBusiness?.data,
            filterFn: (item: any) => item.isActive
        },
    };

    const action:any = filterData[id];
    if (action) {
        action.setter(isChecked ? selectAllOptions(action.items, action.filterFn) : []);
    }
};

  
  const redux_data = useAppSelector((state) => state.counter)
  useEffect(() => {
    if (redux_data?.clear_all_filter) {
      dispatch(storeClearAllFilter(false))
      clearAllFilter(true)
    }
  }, [redux_data?.clear_all_filter])

  useEffect(() => {
    const isDataFetched = !filterCapacityArray?.isfetchlayoutandfilters &&
      !filterCapacityArray?.isPeopleFetch &&
      !filterCapacityArray?.isDepartmentFetch &&
      !filterCapacityArray?.isCompanyFetch &&
      !filterCapacityArray?.isProjectFetch &&
      !filterCapacityArray?.isSkillFetch &&
      !filterCapacityArray?.isRoleFetch &&
      !filterCapacityArray?.isQueuesFetch &&
      !filterCapacityArray?.isTicketCategoryFetch &&
      !filterCapacityArray?.isTicketTypeFetch &&
      !filterCapacityArray?.isTaskCategoryFetch &&
      !filterCapacityArray?.isSlasFetch &&
      !filterCapacityArray?.isContractCategoryFetch && 
      !filterCapacityArray?.islineOfBusinessFetch;
  
    if (!isDataFetched) return;

    let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencestodo = savedPreferences?.task_list_filter || filterCapacityArray?.layoutandfilters?.task_list_filter;

    let data = savedPreferencestodo || {};
  
    if (Object.keys(data).length === 0) {
      clearAllFilter(false);
      return;
    }
    const processFilter = (dataKey, stateSetter, storeAction, filterArrayKey, setshow, isActive = true) => {
      if (data?.[dataKey]?.length > 0) {
        const ids = filterCapacityArray?.[filterArrayKey]?.filter((item) => item.isActive === isActive)?.map((item) => parseInt(item.id));
        const filteredData = data?.[dataKey]?.filter((id) => ids?.includes(id));
        stateSetter(filteredData);
        dispatch(storeAction(filteredData));
        setshow("")
      }
    };
  
    processFilter('companies', setCompanyid, storeCapacityCompanyIds, 'companies',setshowcompany);
    processFilter('resources', setPeopletid, storeCapacityPeopleIds, 'peoples',setshowpeople);
    processFilter('departments', setDepartmentid, storeCapacityDepartmentIds, 'departments.items',setshowdepartment);
    processFilter('projects', setProjectid, storeCapacityProjectIds, 'projects',setshowproject);
    processFilter('roles', setRoleid, storeCapacityRoleIds, 'roles',setshowrole);
    processFilter('queues', setqueuesid, storeCapacityqueuesids, 'queues.data',setshowqueues);
    processFilter('skills', setSkillid, storeCapacityskillids, 'skills.data',setshowskill);
    processFilter('contract_category', setcontractcategoryid, storeCapacitycontractcategoryids, 'contractCategory.data',setshowcontractcategory);
    processFilter('priority', setprioritiesid, storeCapacityprioritiesids, 'priorities.data',setshowpriorities);
    processFilter('project_leads', setprojectleadid, storeCapacityprojectLeadsids, 'peoples',setshowprojectlead);
    processFilter('sla', setslasid, storeCapacityslasids, 'slas.data',setshowslas);
    processFilter('task_categories', settaskcategoryid, storeCapacitytaskCategoryids, 'taskCategory.data',setshowtaskcategory);
    processFilter('ticket_categories', setticketcategoryid, storeCapacityticketCategoryids, 'ticketCategory.data',setshowticketcategory);
    processFilter('ticket_types', settickettypeid, storeCapacityticketTypeids, 'ticketType.data',setshowtickettype);
  
    if (data?.lineOfBusiness?.length > 0) {
      setlineOfBusinessid(data?.lineOfBusiness);
      dispatch(storeCapacitylineOfBusinessIds(data?.lineOfBusiness));
      setshowlineOfBusiness('');
    }
  
  }, [
    filterCapacityArray?.isfetchlayoutandfilters,
    filterCapacityArray?.isPeopleFetch,
    filterCapacityArray?.isDepartmentFetch,
    filterCapacityArray?.isCompanyFetch,
    filterCapacityArray?.isProjectFetch,
    filterCapacityArray?.isSkillFetch,
    filterCapacityArray?.isRoleFetch,
    filterCapacityArray?.isQueuesFetch,
    filterCapacityArray?.isTicketCategoryFetch,
    filterCapacityArray?.isTicketTypeFetch,
    filterCapacityArray?.isTaskCategoryFetch,
    filterCapacityArray?.isSlasFetch,
    filterCapacityArray?.isContractCategoryFetch,
    filterCapacityArray?.islineOfBusinessFetch
  ]);

  useEffect(() => {
    resetSelectedItems(openDropdown)
  },[openDropdown])

  useEffect(() => {
    if(!filterCapacityArray?.isfetchlayoutandfilters && !filterCapacityArray?.isPeopleFetch && !filterCapacityArray?.isDepartmentFetch && !filterCapacityArray?.isCompanyFetch && !filterCapacityArray?.isProjectFetch && !filterCapacityArray?.isSkillFetch && !filterCapacityArray?.isRoleFetch && !filterCapacityArray?.isQueuesFetch && !filterCapacityArray?.isTicketCategoryFetch && !filterCapacityArray?.isTicketCategoryFetch && !filterCapacityArray?.isTicketTypeFetch && !filterCapacityArray?.isTaskCategoryFetch && !filterCapacityArray?.isTaskCategoryFetch && !filterCapacityArray?.isSlasFetch && !filterCapacityArray?.isContractCategoryFetch && !filterCapacityArray?.islineOfBusinessFetch){

      const companyNameArray = filterCapacityArray.companies
            .filter((item: { id: any; }) => Companyid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setCompanyName(companyNameArray)

      const resourceNameArray = filterCapacityArray?.peoples?.filter((item: { id: any; }) => Peopletid.includes(item.id)).map((item: { name: any; }) => item.name)
      if(Peopletid.includes("noresource")){
        resourceNameArray.push("Empty")
      }
      setPeopletName(resourceNameArray) 
      
      const departMentNameArray = filterCapacityArray.departments.items
          .filter((item: { id: any; }) => Departmentid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setDepartmentName(departMentNameArray)

      const projectNameArray = filterCapacityArray.projects
          .filter((item: { id: any; }) => Projectid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setProjectName(projectNameArray)
    
      const roleNameArray = filterCapacityArray.roles
          .filter((item: { id: any; }) => Roleid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setRoleName(roleNameArray)
      
      const queueNameArray = filterCapacityArray.queues.data
          .filter((item: { id: any; }) => queuesid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setqueuesName(queueNameArray)

      const skillNameArray = filterCapacityArray?.skills?.data?.filter((item: { id: any; }) => Skillid?.includes(item?.id))?.map((item: { name: any; }) => item?.name)
      setSkillName(skillNameArray)

      const contractcategoryNameArray = filterCapacityArray?.contractCategory?.data
          .filter((item: { id: any; }) => contractcategoryid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setcontractcategoryName(contractcategoryNameArray)

      const priorityNameArray = filterCapacityArray?.priorities?.data
          .filter((item: { id: any; }) => prioritiesid.includes(item.id))
          .map((item: { name: any; }) => item.name)
      setprioritiesName(priorityNameArray)

      const project_leadsNameArray = filterCapacityArray?.peoples?.filter((item: { id: any; }) => projectleadid.includes(item.id)).map((item: { name: any; }) => item.name)
      setprojectleadName(project_leadsNameArray)

      const slasNameArray = filterCapacityArray?.slas?.data?.filter((item: { id: any; }) => slasid.includes(item.id)).map((item: { name: any; }) => item.name)
      setslasName(slasNameArray)

      const task_categoriesNameArray = filterCapacityArray?.taskCategory?.data?.filter((item: { id: any; }) => taskcategoryid.includes(item.id)).map((item: { name: any; }) => item.name)
      settaskcategoryName(task_categoriesNameArray)
   
      const ticket_categoriesNameArray = filterCapacityArray?.ticketCategory?.data?.filter((item: { id: any; }) => ticketcategoryid.includes(item.id)).map((item: { name: any; }) => item.name)
      setticketcategoryName(ticket_categoriesNameArray)

      const ticket_typesNameArray = filterCapacityArray?.ticketType?.data?.filter((item: { id: any; }) => tickettypeid.includes(item.id)).map((item: { name: any; }) => item.name)
      settickettypeName(ticket_typesNameArray)
      
      const lineOfBusinessNameArray = filterCapacityArray?.lineOfBusiness?.data?.filter((item: { id: any; }) => lineOfBusinessid.includes(item.id)).map((item: { name: any; }) => item.name)
      setlineOfBusinessName(lineOfBusinessNameArray)
    
    }
  }, [Companyid, Peopletid, Departmentid,Projectid, Roleid, queuesid,Skillid,contractcategoryid, prioritiesid,projectleadid,slasid,taskcategoryid ,ticketcategoryid, tickettypeid, lineOfBusinessid]);

  return (
    <>
      <div className="dropDown d-flex align-items-center mb-2">
        <button 
            onClick={() => {
              toggleDropdown("main-planbucket-filter")
            }} 
            id={`main-planbucket-filter`}
            className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-1 min-h-36px fs-14 d-flex align-items-center me-2" 
            type="button" 
            data-kt-menu-trigger="click" 
            data-kt-menu-attach="parent" 
            data-kt-menu-placement="bottom-start" 
            data-kt-menu-flip="bottom">
          <KTSVG path="/media/icons/duotune/new-icons/filter.svg" className='me-2' stylecode={{ color: "inherit" }} svgClassName="w-16px h-14px" />
          Filter
        </button>
        {openDropdown === "main-planbucket-filter" &&
        (<div className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 show position-absolute top-100 start-0" data-kt-menu="true">
          <a onClick={ChangeFilter} data-name="people" className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" href="#">
            <KTSVG path="/media/icons/duotune/new-icons/resources.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Resources</a>
          <a onClick={ChangeFilter} data-name="department" className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" href="#">
            <KTSVG path="/media/icons/duotune/extra/mouse-square.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Department</a>
          <a onClick={ChangeFilter} data-name="company" className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" href="#">
            <KTSVG path="/media/icons/duotune/new-icons/company.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Company</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="project" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/project.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Project</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="role" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/role.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Role</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="projectlead" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/project-leads.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Project Leads</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="queues" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/queues.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Queues</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="priorities" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/priorities.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Priorities</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="tickettype" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/ticket-type.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket type</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="ticketcategory" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/ticket-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Ticket category</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="taskcategory" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/task-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Task category</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="slas" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/slas.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />SLAs</a>
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="contractcategory" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/contract-category.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Contract category</a>
          {
            general_settings?.show_resource_skills === true &&
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="Skills" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/new-icons/skills.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Skills</a>
          }
          <a className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap" data-name="LineOfBusiness" onClick={ChangeFilter} href="#">
            <KTSVG path="/media/icons/duotune/extra/note.svg" className='w-20px me-1 text-primary' svgClassName="w-16px h-auto" />Line of business</a>
        </div>)
        }
      </div>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/profile-user-2.svg" inputHandler={inputHandler} inputText={peopleinputText} currentClick={currentClick} checked_ids={Peopletid} title="cPeople" display_title="People" data={filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isPeopleFetch} showdropdown={showpeople} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={PeopletName} selectAll={selectAll} tooltipTitle='Filter resources on name.' isOpen={openDropdown === 'cPeople'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/mouse-square.svg" inputHandler={inputHandler} inputText={departmentinputText} currentClick={currentClick} checked_ids={Departmentid} title="cDepartment" display_title="Department" data={filterCapacityArray?.departments?.items} isLoading={filterCapacityArray?.isDepartmentFetch} showdropdown={showdepartment} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={DepartmentName} selectAll={selectAll} tooltipTitle='Filter resources on their default department.' isOpen={openDropdown === 'cDepartment'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/profile-user-1.svg" inputHandler={inputHandler} inputText={companyinputText} currentClick={currentClick} checked_ids={Companyid} title="cCompany" display_title="Company" data={filterCapacityArray?.companies?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isCompanyFetch} showdropdown={showcompany} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={CompanyName} selectAll={selectAll} tooltipTitle='Filter resources on their company.' isOpen={openDropdown === 'cCompany'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/project.svg" inputHandler={inputHandler} inputText={projectinputText} currentClick={currentClick} checked_ids={Projectid} title="cProject" display_title="Project" data={filterCapacityArray?.projects?.filter((twork: any) => (twork.isActive === true && twork.isCompleted === false))} isLoading={filterCapacityArray?.isProjectFetching} showdropdown={showproject} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={ProjectName} selectAll={selectAll} tooltipTitle='Filter resources on their project.' isOpen={openDropdown === 'cProject'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/role.svg" inputHandler={inputHandler} inputText={roleinputText} currentClick={currentClick} checked_ids={Roleid} title="cRole" display_title="Role" data={filterCapacityArray?.roles?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isRoleFetching} showdropdown={showrole} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={RoleName} selectAll={selectAll} tooltipTitle='Filter resources on their roles.' isOpen={openDropdown === 'cRole'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/project-leads.svg" inputHandler={inputHandler} inputText={projectleadinputText} currentClick={currentClick} checked_ids={projectleadid} display_title="projectlead" title="cprojectlead" data={filterCapacityArray?.peoples?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isPeopleFetch} showdropdown={showprojectlead} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={projectleadName} selectAll={selectAll} tooltipTitle='Filter data on their project leads.' isOpen={openDropdown === 'cprojectlead'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/queues.svg" inputHandler={inputHandler} inputText={queuesinputText} currentClick={currentClick} checked_ids={queuesid} title="cqueues" display_title="queues" data={filterCapacityArray?.queues?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isQueuesFetch} showdropdown={showqueues} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={queuesName} selectAll={selectAll} tooltipTitle='Filter data on their queues.' isOpen={openDropdown === 'cqueues'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/priorities.svg" inputHandler={inputHandler} inputText={prioritiesinputText} currentClick={currentClick} checked_ids={prioritiesid} title="cPriorities"  display_title="Priorities" data={filterCapacityArray?.priorities?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isPrioritiesFetch} showdropdown={showpriorities} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={prioritiesName} selectAll={selectAll} tooltipTitle='Filter data on their priorities.' isOpen={openDropdown === 'cPriorities'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/ticket-type.svg" inputHandler={inputHandler} inputText={tickettypeinputText} currentClick={currentClick} checked_ids={tickettypeid} title="cTicket type" display_title="Ticket type" data={filterCapacityArray?.ticketType?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isTicketTypeFetch} showdropdown={showtickettype} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={tickettypeName} selectAll={selectAll} tooltipTitle='Filter data on their ticket type.' isOpen={openDropdown === 'cTicket type'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/ticket-category.svg" inputHandler={inputHandler} inputText={ticketcategoryinputText} currentClick={currentClick} checked_ids={ticketcategoryid} display_title="Ticket category" title="cTicket category" data={filterCapacityArray?.ticketCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isTicketCategoryFetch} showdropdown={showticketcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={ticketcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their ticket category.' isOpen={openDropdown === 'cTicket category'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/task-category.svg" inputHandler={inputHandler} inputText={taskcategoryinputText} currentClick={currentClick} checked_ids={taskcategoryid} display_title="Task category" title="cTask category" data={filterCapacityArray?.taskCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isTaskCategoryFetch} showdropdown={showtaskcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={taskcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their task category.' isOpen={openDropdown === 'cTask category'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/slas.svg" inputHandler={inputHandler} inputText={slasinputText} currentClick={currentClick} checked_ids={slasid} display_title="SLAs" title="cSLAs" data={filterCapacityArray?.slas?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isSlasFetch} showdropdown={showslas} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={slasName} selectAll={selectAll} tooltipTitle='Filter data on their slas.' isOpen={openDropdown === 'cSLAs'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/contract-category.svg" inputHandler={inputHandler} inputText={contractcategoryinputText} currentClick={currentClick} checked_ids={contractcategoryid} display_title="Contract category" title="cContract category" data={filterCapacityArray?.contractCategory?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isContractCategoryFetch} showdropdown={showcontractcategory} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={contractcategoryName} selectAll={selectAll} tooltipTitle='Filter data on their contract category.' isOpen={openDropdown === 'cContract category'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>
      
      <CapacityDropDown imgpath="/media/icons/duotune/new-icons/skills.svg" inputHandler={inputHandler} inputText={skillinputText} currentClick={currentClick} checked_ids={Skillid} title="cSkills" display_title="Skills" data={filterCapacityArray?.skills?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.isSkillFetch} showdropdown={showskill} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={SkillName} selectAll={selectAll} tooltipTitle='Filter data on their Skills.' isOpen={openDropdown === 'cSkills'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>

      <CapacityDropDown imgpath="/media/icons/duotune/extra/note.svg" inputHandler={inputHandler} inputText={lineOfBusinessinputText} currentClick={currentClick} checked_ids={lineOfBusinessid} title="cLineOfBusiness" display_title="LineOfBusiness" data={filterCapacityArray?.lineOfBusiness?.data?.filter((twork: any) => (twork.isActive === true))} isLoading={filterCapacityArray?.islineOfBusinessFetch} showdropdown={showlineOfBusiness} hideSelection={hideSelection} storeFilterValue={storeFilterValue} handleChange={handleChange} selectedName={lineOfBusinessName} selectAll={selectAll} tooltipTitle='Filter data on their line of business.' isOpen={openDropdown === 'cLineOfBusiness'}
          toggleDropdown={toggleDropdown}></CapacityDropDown>
    </>
  );
}

export default FilterCapacity
