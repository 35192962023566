import React, { useEffect, useState, FC } from 'react';
import { getOrganisationById } from '../../modules/superadmin/organisations/organisation-list/core/_requests';
import { useQuery } from 'react-query';
import { useAuth } from '../../modules/auth/core/AuthCognito';
import { useNavigate } from 'react-router';
import { Organisation } from '../../modules/superadmin/organisations/organisation-list/core/_models';

type Props = {};

  
const EUcountries = [
  { name: "Austria", value: "AT" },
  { name: "Belgium", value: "BE" },
  { name: "Bulgaria", value: "BG" },
  { name: "Croatia", value: "HR" },
  { name: "Cyprus", value: "CY" },
  { name: "Czechia", value: "CZ" },
  { name: "Denmark", value: "DK" },
  { name: "Estonia", value: "EE" },
  { name: "Finland", value: "FI" },
  { name: "France", value: "FR" },
  { name: "Germany", value: "DE" },
  { name: "Greece", value: "GR" },
  { name: "Hungary", value: "HU" },
  { name: "Ireland", value: "IE" },
  { name: "Italy", value: "IT" },
  { name: "Latvia", value: "LV" },
  { name: "Lithuania", value: "LT" },
  { name: "Luxembourg", value: "LU" },
  { name: "Malta", value: "MT" },
  { name: "Netherlands", value: "NL" },
  { name: "Poland", value: "PL" },
  { name: "Portugal", value: "PT" },
  { name: "Romania", value: "RO" },
  { name: "Slovakia", value: "SK" },
  { name: "Slovenia", value: "SI" },
  { name: "Spain", value: "ES" },
  { name: "Sweden", value: "SE" }
];

const CompanyInfoPopup: FC = () => {
  const navigate = useNavigate();
  const isvisible:any = localStorage.getItem('show_companyinfo');
  const isIgnore:any = localStorage.getItem('isIgnoreClick');
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [isVisible, setIsVisible] = useState<boolean>(isvisible === '1'? true : false);
  const { currentUser } = useAuth();
  const { refetch, data: myOrganisation } = useQuery(
    `my-organisation`,
    async () => {
      return await getOrganisationById(currentUser?.data?.user?.organizationId);
    },
    {
      // enabled: isShown === true && isIgnore === '1' ? false : true, // do not remove this comment
      enabled: isvisible === '1' && isIgnore === '1' ? false : true,
      cacheTime: 0,
      onSuccess: (data) => {
        localStorage.setItem('isIgnoreClick', "1")
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

 const IsEu =  EUcountries.some((euCountry) => euCountry.value === myOrganisation?.country) &&  myOrganisation?.country !== 'NL';


  useEffect(() => {
    if (myOrganisation) {
      const companyFields = [
      
        'company_name',
        'first_name',
        'last_name',
        'email',
        'invoice_email',
        'zipcode',
        'street',
        'city',
        'country',
        'user_name',
        'secret',
        'zoneInfo',
        'billing_name',
        'billing_address',
        'billing_zipcode',
       
      ];
  
      // Add 'vat_number' and 'Country_type' only when is_Eucountry is true and country is not 'NL'
      if (IsEu && myOrganisation.country !== 'NL') {
        companyFields.push('vat_number');
      }
  
      let missing = [];
      companyFields.filter((field) => {
        if (!(field in myOrganisation)) {
          missing.push(field);
        } else {
          // Check if the field is empty for vat_number and Country_type
          if (
            (field === 'vat_number') &&
            (myOrganisation[field] === '' ||
              myOrganisation[field] === undefined ||
               myOrganisation[field] === null)
          ) {
            if(field === 'vat_number'){
              field = 'VAT Identification Number'
            }
            missing.push(field);
          } else if (
            myOrganisation[field as keyof Organisation] === '' ||
            myOrganisation[field as keyof Organisation] === undefined ||
            myOrganisation[field as keyof Organisation] === null
          ) {
            missing.push(field);
          }
        }
      });
  
      // Additional condition to exclude certain fields when default_billing_address is true
      if (myOrganisation.default_billing_address === true) {
        const excludeFields = [
          'billing_name',
          'billing_address',
          'billing_zipcode',
          'default_billing_address',
        ];
        setMissingFields(
          missing.filter((field) => !excludeFields.includes(field))
        );
      } else {
        setMissingFields(missing);
      }
    }
  }, [myOrganisation, isVisible]);

  const redirectToMyOrganisationPage = () => {
    navigate('/apps/organisation');
    setIsVisible(false);
  };

  const handleIgnoreClick = () => {
    setIsVisible(false);
    localStorage.setItem("isIgnoreClick", '1');
  };
  // Display the popup if there are missing fields and it's visible
  if ((missingFields.length > 0 && isVisible) || myOrganisation?.autotask_auth_failed === true) {

    return (

      <><div className={`modal fade ${isVisible ? 'show d-block':'d-none'}`} tab-index="-1" aria-labelledby="exampleModalSizeLg" role="dialog" style={{ backgroundColor : 'rgba(0,0,0,0.3)' }} >
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title fs-18">Required information missing</h5>
                    {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close"></i>
                    </button> */}
                </div>
                <div className={`modal-body ${myOrganisation.default_billing_address === true && myOrganisation?.autotask_auth_failed === true ? 'pt-0' : ''}`}>
                    <form
                      className="form w-100"
                      noValidate
                      id="kt_login_signin_form"
                    >
                      {missingFields.length > 0 && (
                      <div className="mb-5">
                      <span className="fs-16">Some information is missing for billing, please provide the  details:</span>
                      </div>
                      )}

                     
                      {/* begin::Form group */}
                      <div className="fv-row mb-8">
                        <ul>
                          {missingFields.map((field) => 
                            {
                              let name:any = field.split("_");
                              name = name[0]?.charAt(0).toUpperCase() + name[0]?.slice(1) + ' '+ (name?.[1] ? name[1] : '')
                              return <><li key={field}><label className='fs-16 text-dark fw-bold pb-1'>{name}</label></li></>
                            }
                          )}
                        </ul>
                      </div>
                      {/* End::Form group */}
                      {myOrganisation?.autotask_auth_failed === true && (
                        <>
                          <div className="mb-5">
                            <span className="fs-16">Sync has been stopped due to invalid credentials. Please update your credentials to resume the sync process:</span>
                          </div>
                          <div className="fv-row mb-8">
                            <ul>
                              <li>
                                <label className='fs-16 text-dark fw-bold pb-1'>
                                  Autotask Client ID
                                </label>
                              </li>
                              <li>
                                <label className='fs-16 text-dark fw-bold pb-1'>
                                  Autotask Client Secret
                                </label>
                              </li>
                            </ul>
                          </div>
                        </>
                      )}
                    
                      <div className="">
                        {/* begin::Action */}
                        <button
                          type="button"
                          id="kt_sign_in_submit"
                          onClick={redirectToMyOrganisationPage}
                          className="btn btn-primary text-left h-32px p-0 px-5 me-3"   /* text-toggle-hover */
                        >
                          <span className="indicator-label"><small className="fs-100">Update Now</small></span>
                        </button>&nbsp;
                        <button
                          type='reset'
                          className='btn btn-secondary text-left h-32px p-0 px-5'
                          data-kt-role-modal-action='close'
                          onClick={handleIgnoreClick}
                        >
                          Ignore
                        </button>
                        {/* end::Action */}
                      </div>
                    </form>
                </div>
            </div>
        </div>
    </div></>
      
    );
  }

  return null; // No missing fields or the popup is not visible
}

export default CompanyInfoPopup;
